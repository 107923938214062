import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableDataUsers, Pagination } from 'api/clinicsGet.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import * as S from '../../medical-dashboard/favoriteDoctors/DoctorCard/DoctorCard.styles';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getAllDoctorsAction, getAllInternalUsersAction } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import { readUser } from '@app/services/localStorage.service';
const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
};

export const BasicTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const dispatch = useAppDispatch();
  const clinicDetails=readUser();
  console.log({clinicDetails})
  useEffect(() => {
    dispatch(getAllDoctorsAction(clinicDetails!.id))
      .unwrap()
      .then((resp) => {

        setTableData({ ...tableData, data: resp });

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, []);

  const { t } = useTranslation();
  const { isMounted } = useMounted();
  let navigate = useNavigate();
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      // getBasicTableDataUsers(pagination).then((res) => {
      //   if (isMounted.current) {
      //     setTableData({ data: res.data, pagination: res.pagination, loading: false });
      //   }
      // });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      render: (text: string) => <span>{text}</span>,
   
    },

    {
      title: 'Last Name',
      dataIndex: 'lastName',
      render: (text: string) => <span>{text}</span>,
    },
    // {
    //   title: t('common.age'),
    //   dataIndex: 'age',
    //   sorter: (a: BasicTableRow, b: BasicTableRow) => a.age - b.age,
    //   showSorterTooltip: false,
    // },
    {
      title: t('common.email'),
      dataIndex: 'email',
    },
    {
      title: t('common.phone'),
      dataIndex: 'phone',
    },
    {
      title: t('common.city'),
      dataIndex: 'gaunpalikaDetail.gaunpalika_name',
    },
  
    // {
    //   title: t('common.status'),
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (status: String) => (
    //     <BaseRow gutter={[10, 10]}>
      
       
    //           <BaseCol>
    //             <Status color={status ? 'var(--success-color)' : 'var(--error-color)'} text={status ? 'ACTIVE' : 'INACTIVE'} />
    //           </BaseCol>
         
        
    //     </BaseRow>
    //   ),
    // },
    //     {
    //   title: "Rating",
    //   key: 'rating',
    //   dataIndex: 'rating',
    //   render: (status: String) => (
    //     <BaseRow gutter={[10, 10]}>
      
       
    //           <BaseCol>
    //           <S.Rating value={4} disabled />
    //           </BaseCol>
         
        
    //     </BaseRow>
    //   ),
    // },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate('/company/doctors/edit/'+record!.id)
                // notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
              }}
            >
              {t('common.edit')}
            </BaseButton>
            {/* <BaseButton type="default" danger>
              {t('common.markInactive')}
            </BaseButton> */}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
