import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { Checkbox, Modal, TimePicker } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseCheckbox } from '../common/BaseCheckbox/BaseCheckbox';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseButton } from '../common/BaseButton/BaseButton';
import moment from 'moment';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { updateAvailabilityClinicAction } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import { readUser } from '@app/services/localStorage.service';
import config from '@app/config';



const EditAvailabilityModal: React.FC<any> = (props) => {
    const { showAvailabilityModal, setShowAvailabilityModal, formData, days } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    const timeFormat = 'HH:mm';
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const clinicDetails = props.clinicDetails;
    const defaultEntry = {
        days: [...days],
        session1Start: "9:00",
        session1End: "19:00",
        session2Start: undefined,
        session2End: undefined,

    }

    const [theEntries, setTheEntries] = useState<any[]>(props.theEntriesParent ? props.theEntriesParent : [
        {
            days: [...days],
            session1Start: "9:00",
            session1End: "19:00",
            session2Start: undefined,
            session2End: undefined
        }
    ]);
    const [allDaysSelected, setAllDaysSelected] = useState<any[]>([]);

    const user = readUser();



    useEffect(() => {

        let _allDaysSelected = theEntries.flatMap(d => d.days.map((x: string) => x));

        console.log({ _allDaysSelected });
        setAllDaysSelected(_allDaysSelected);

    }, [theEntries]);


    const onFieldChange = (e: any, type: keyof typeof theEntries[0], index: number) => {

        console.log({ e })
        let _theEntries = theEntries;

        _theEntries[index][type] = e;

        console.log({ _theEntries });
        setTheEntries([..._theEntries]);
    }

    const onFinish = () => {

        let detailedEntries = {
            Mon: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Tue: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Wed: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Thu: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Fri: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Sat: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
            Sun: { session1Start: "", session1End: "", session2Start: "", session2End: "" },
        }

        let arr = "";
        theEntries.map(x => {
            for (const key of Object.keys(detailedEntries) as (keyof typeof detailedEntries)[]) {
                console.log(`${key}: ${detailedEntries[key]}`);
                if (x.days.includes(key)) {
                    detailedEntries[key].session1Start = x.session1Start;
                    detailedEntries[key].session2Start = x.session2Start;
                    detailedEntries[key].session1End = x.session1End;
                    detailedEntries[key].session2End = x.session2End;

                    if (clinicDetails!.role_id == config.roleIds.Doctor) {

                        let _formDataClinic = props.formDataClinic;


                        console.log('comparing --- ' + _formDataClinic[key].session1Start + ' with ' + detailedEntries[key].session1Start)
                        if ((_formDataClinic[key].session1Start == "" || !_formDataClinic[key].session1Start) && (detailedEntries[key].session1Start && detailedEntries[key].session1Start != "")) {

                            console.log('key-- ', key + ' failed');
                            arr += key + ' clinic not operational, ';

                        }
                    }
                }
            }
        })

        if (arr !== "") {
            notificationController.error({ message: <>{arr}</> });
            return;
        }

        let args = {
            availability: JSON.stringify(theEntries),
            detailedAvailability: JSON.stringify(detailedEntries),
            clinicId: user!.id
        }

        dispatch(updateAvailabilityClinicAction(args))
            .unwrap()
            .then((resp: any) => {
                setLoading(false);
                if (resp && !resp.error && !resp.Error) {
                    notificationController.success({ message: t('common.success') });
                    navigate(0);

                }
                else {
                    notificationController.error({ message: 'Failed to save availability info' });
                }

            }).catch((err: any) => {
                setLoading(false);
                notificationController.error({ message: err.message });
            })


        console.log({ detailedEntries })
    }


    console.log({ allDaysSelected })
    return (
        <BaseModal getContainer={false} size={'large'} {...props} open={showAvailabilityModal} onCancel={() => setShowAvailabilityModal(false)} onOk={() => setShowAvailabilityModal(false)}
            footer={<BaseButton onClick={() => onFinish()}>Save</BaseButton>}>

            <div>
                <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Edit Availability</h3>

                {theEntries && theEntries.map((entry, index) =>
                    <div key={entry.days.join('-')} style={{
                        marginBottom: '20px', border: '1px solid gray',
                        padding: '20px'
                    }}>
                        <BaseCheckbox.Group value={entry.days} onChange={(e) => { onFieldChange(e, 'days', index) }}>
                            {days.map((x: any) =>
                                <BaseCheckbox key={x} value={x} disabled={allDaysSelected.includes(x) && !entry.days.includes(x)}>{x}</BaseCheckbox>
                            )}
                        </BaseCheckbox.Group>


                        <div style={{ marginTop: '10px' }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Session 1</label>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TimePicker style={{ marginBottom: '10px' }} placeholder='Start Time' allowClear={false} value={moment(entry.session1Start, timeFormat)} format={timeFormat}
                                    onChange={(e) => onFieldChange(moment(e).format(timeFormat), 'session1Start', index)} />
                                <TimePicker placeholder='End Time' allowClear={false} value={moment(entry.session1End, timeFormat)} format={timeFormat}
                                    onChange={(e) => onFieldChange(moment(e).format(timeFormat), 'session1End', index)}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px', opacity: (entry.session1Start == "" || entry.session1End == "") ? 0.7 : 1 }}>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Session 2</label>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <TimePicker style={{ marginBottom: '10px' }} placeholder='Start Time' value={entry.session2Start ? moment(entry.session2Start, timeFormat) : undefined} format={timeFormat}
                                    onChange={(e) => onFieldChange(moment(e).format(timeFormat), 'session2Start', index)} />
                                <TimePicker placeholder='End Time' value={entry.session2End ? moment(entry.session2End, timeFormat) : undefined} format={timeFormat}
                                    onChange={(e) => onFieldChange(moment(e).format(timeFormat), 'session2End', index)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
                            <h2 style={{ color: 'red', fontSize: 40, cursor: 'pointer', display: theEntries.length == 1 ? 'none' : '', maxWidth: '5vw' }} onClick={() => {

                                theEntries.splice(index, 1);
                                setTheEntries([...theEntries]);


                            }}>-</h2>
                        </div>

                    </div>
                )}
            </div>
            <BaseButton onClick={() => {
                let _defaultEntry = defaultEntry;
                _defaultEntry.days = [];
                theEntries.push(_defaultEntry);
                setTheEntries([...theEntries]);
            }}>Add More Timings +</BaseButton>
        </BaseModal>
    );
};

export default EditAvailabilityModal;
