import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { changePasswordViaOtpAction, doSetNewPassword } from '@app/store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { NewPasswordItem } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/NewPasswordItem/NewPasswordItem';
import { ConfirmItemPassword } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/ConfirmPasswordItem/ConfirmPasswordItem';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: null,
  confirmPassword: null,
};

export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(doSetNewPassword({ newPassword: values.password }))
      .unwrap()
      .then(() => {
        navigate('/auth/login');
        notificationController.success({
          message: t('newPassword.successMessage'),
          description: t('newPassword.successDescription'),
        });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };



  const onFinish = async (values: any) => {
    setLoading(true);


    // return;
    let args = {
      otp: localStorage.getItem('otpForPassword'),
      email: localStorage.getItem('emailForPass'),
      password: values.newPassword
    }
    dispatch(changePasswordViaOtpAction(args))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error && resp.success) {
          localStorage.removeItem('emailForPass');
          localStorage.removeItem('otpForPassword');
          notificationController.success({ message: "Successful, Please Login Using New Password" });

          navigate('/auth/login');
        }
        else {
          notificationController.error({ message: 'Failed to change info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={onFinish} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
        <S.Description>{t('newPassword.description')}</S.Description>
   
          <NewPasswordItem />
   

 
          <ConfirmItemPassword />
     
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.resetPassword')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
