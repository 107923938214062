import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { NftItem } from '@app/api/nftDashboard.api';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import * as S from './ClientCard.styles';

interface ClientCardProps {
  nftItem: NftItem;
}

export const ClientCard: React.FC<any> = ({ nftItem }) => {
  const { isTablet } = useResponsive();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>@{nftItem.email} </S.InfoText>
      </S.InfoHeader>

      {/* <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.CurrentBid>Some Info xxxxx</S.CurrentBid>
          <S.BidCrypto>
            {getCurrencyPrice(formatNumberWithCommas(nftItem.currentBidCrypto), CurrencyTypeEnum.ETH, false)}
            Some description here
          </S.BidCrypto>
        </S.CurrentBidWrapper>

        <S.CurrentBidWrapper>
          <S.Bid>{getCurrencyPrice(formatNumberWithCommas(nftItem.currentBid), CurrencyTypeEnum.USD)}</S.Bid>
        </S.CurrentBidWrapper>
      </S.InfoFooter> */}
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>@{nftItem.email}</S.InfoText>
        <S.BidCrypto>
          {/* {getCurrencyPrice(formatNumberWithCommas(nftItem.currentBidCrypto), CurrencyTypeEnum.ETH, false)} */}
        </S.BidCrypto>
      </S.InfoRow>

      {/* <S.InfoRow>
        <S.CurrentBid>Current Bid</S.CurrentBid>
        <S.Bid>{getCurrencyPrice(formatNumberWithCommas(nftItem.currentBid), CurrencyTypeEnum.USD)}</S.Bid>
      </S.InfoRow> */}
    </>
  );

  return (
    <S.ClientCard padding={0} $img={nftItem.image}>
      {/* <S.NftImage src={nftItem.image} alt="nftImage" /> */}
      <S.NftInfo>
        <S.InfoRow>
          <S.Title>{nftItem?.firstName} {nftItem?.lastName}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.NftInfo>
    </S.ClientCard>
  );
};
