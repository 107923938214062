import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MyFacilitiesComponent from '@app/components/manage-facilities/list';

const ManageFacility: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>Manage Facility</PageTitle>
      <MyFacilitiesComponent />
    </>
  );
};

export default ManageFacility;
