import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination } from 'api/clinicsGet.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getAllClinicsAction } from '@app/store/slices/userSlice';
const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
};

export const BasicTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  let navigate = useNavigate();


  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllClinicsAction(1))
      .unwrap()
      .then((resp) => {

        setTableData({ ...tableData, data: resp });

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, []);

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      // getBasicTableData(pagination).then((res) => {
      //   if (isMounted.current) {
      //     setTableData({ data: res.data, pagination: res.pagination, loading: false });
      //   }
      // });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: 'Name 1',
      dataIndex: 'firstName',
      render: (text: string) => <span>{text}</span>,
   
    },

    {
      title: 'Name 2',
      dataIndex: 'lastName',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: t('common.email'),
      dataIndex: 'email',
    },
    {
      title: t('common.phone'),
      dataIndex: 'phone',
    },
    {
      title: t('common.city'),
      dataIndex: 'gaunpalikaDetail.gaunpalika_name',
    },

    {
      title: t('common.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: String) => (
        <BaseRow gutter={[10, 10]}>


          <BaseCol>
            <Status color={status ? 'var(--success-color)' : 'var(--error-color)'} text={status ? 'ACTIVE' : 'INACTIVE'} />
          </BaseCol>


        </BaseRow>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate('/admin/clinics/edit/' + record?.id)
                // notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
              }}
            >
              {t('common.edit')}
            </BaseButton>

            {/* <BaseButton
              type="ghost"
              onClick={() => {
                navigate('/admin/clinics/patients/list')
                // notificationController.info({ message: t('tables.inviteMessage', { name: record.name }) });
              }}
            >
              Patients
            </BaseButton>


            <BaseButton type="default" danger>
              {t('common.markInactive')}
            </BaseButton> */}
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
