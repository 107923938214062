import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined, CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import config from '@app/config';
import { createInternalUserAction, getAllDoctorsAction, getAvailabilitySlotsOfDoInfoAction } from '@app/store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import moment from 'moment';
import { createAppointmentAction, getAppointmentByMobileAction } from '@app/store/slices/appointmentSlice';
import { SuffixInput } from '@app/components/common/inputs/SuffixInput/SuffixInput';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { string } from 'joi';
import { Form, TimePicker } from 'antd';
import dayjs from 'dayjs';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
const timeFormat = "h:mm:ss a";

export const ValidationFormAppointmentAdd: React.FC = () => {
  const formRef = useRef<any>();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState<string>('');
  const [formData, setFormData] = useState<any>({});
  const [formDataTemp, setFormDataTemp] = useState<any>({});
  const [doctors, setDoctors] = useState([]);
  const [availabilityDetails, setAvailabilityDetails] = useState(null);
  const [doctorAppointments, setDoctorAppointments] = useState<any[]>([]);
  const [patientRecords, setPatientRecords] = useState<any[]>([]);
  const [date, setDate] = useState<string>("");

  const { t } = useTranslation();
  const [value, setValue] = useState('Male');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clinicDetails = readUser();
  if (clinicDetails!.role_id == config.roleIds.Doctor) clinicDetails!.id = clinicDetails!.clinic_id;


  console.log({ value });




  useEffect(() => {
    setPatientRecords([]);
  }, [phone]);


  useEffect(() => {


    dispatch(getAllDoctorsAction(clinicDetails!.id))
      .unwrap()
      .then((resp) => {

        setDoctors(resp);

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, []);


  const onFinish = async (values: any) => {

    setLoading(true);

    values.clinic_id = clinicDetails?.id;
    values.dob = values.dob.toISOString();
    values.date = moment(values.date, 'ddd, MMM DD').format('MM-DD-YYYY');
    // values.time = moment(new Date(values.time)).format('LT');
    values.gender = value;
    console.log(values);





    setFieldsChanged(false);


    dispatch(createAppointmentAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (!resp.error && !resp.Error) {
          notificationController.success({ message: "Appointment Created" });

          navigate('/company/appointments/list');
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })



  };
  console.log({ phone })

  const getUserByPhone = () => {
    console.log({ phone })

    let params = {
      phone: phone,
      clinic_id: clinicDetails?.id
    }
    dispatch(getAppointmentByMobileAction(params))
      .unwrap()
      .then((resp) => {
        console.log('resprespresp', { resp })
        if (resp && !resp.error && !resp.Error) {

          if (resp.length > 0) {
            notificationController.success({ message: 'Patient Exists' });
            setPatientRecords(resp);
          }
          else {
            setFormDataTemp({});
            notificationController.error({ message: 'No data found' });
          }


        }



      }).catch((err) => {
        setLoading(false);
        setFormDataTemp({});

        notificationController.error({ message: err.message });
      })
  }

  const setFoundRecord = (e: any, option: any) => {

    console.log({ e });
    console.log({ option });

    let obj = JSON.parse(option.label);

    setLoading(true);
    obj.dob = dayjs(obj.dob);
    let args = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      dob: obj.dob,
      phone: obj.phone,
      gender: obj.gender

    }



    setTimeout(() => {

      setFormData({ ...args });
      setLoading(false);

    }, 400);


  }


  const getAvailabilityOfDoc = (id: number) => {



    dispatch(getAvailabilitySlotsOfDoInfoAction(id))
      .unwrap()
      .then((resp) => {
        console.log('resprespresp', { resp })
        if (resp && !resp.error && !resp.Error) {
          setAvailabilityDetails(resp.availableSlots);
          setDoctorAppointments(resp.doctorAppointments);
          formRef?.current?.setFieldsValue({ date: undefined, time: undefined });
        }
        else {

          notificationController.warning({ message: 'No data found' });
        }


      }).catch((err) => {
        setLoading(false);


        notificationController.error({ message: err.message });
        setAvailabilityDetails(null);
        setDoctorAppointments([]);
        formRef?.current?.setFieldsValue({ date: undefined, time: undefined });
      })
  }



  const checkIfAppointmentAvailable = (date: string, time: string) => {
    let count = 0;
    for (let i = 0; i < doctorAppointments.length; i++) {
      const appointment = doctorAppointments[i];
      const formattedDate = moment(appointment.date, 'MM-DD-YYYY').format('ddd, MMM DD');
      if (formattedDate === date && appointment.time === time) {
        count++;
      }
    }
    console.log({ count });
    return count >= 2 ? false : true
  }

  return (
    <>
      {!isLoading &&
        <Form
          ref={formRef}
          {...formItemLayout}
          onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          initialValues={formData}

          onFinish={onFinish}
        >
          <BaseRow gutter={[10, 10]}>

            <BaseCol xs={12} sm={8} md={12} xl={6}>


              <BaseButtonsForm.Item
                name="phone"
                label={"Phone"}
                rules={[{ required: true, message: "Phone is required" },

                ]}
              >
                <SuffixInput
                  onChange={(e) => (setPhone(e.target.value))}
                  suffix={
                    <BaseTooltip title={"Check records"}>
                      <BaseButton size="small" disabled={phone && phone!.length >= 10 ? false : true} type="text" icon={<SearchOutlined />} onClick={() => getUserByPhone()} />
                    </BaseTooltip>
                  }
                />
              </BaseButtonsForm.Item>
            </BaseCol>


            {patientRecords && patientRecords.length > 0 &&

              <BaseCol xs={12} sm={8} md={12} xl={6}>


                <BaseButtonsForm.Item
                  name="patientsList"
                  label={"Records Found"}

                >
                  <BaseSelect placeholder={"Please select a patient"} onChange={setFoundRecord}>

                    {patientRecords && patientRecords.map((item: any) =>

                      <Option value={item.id} label={JSON.stringify(item)}>{item.firstName} {item.lastName}</Option>

                    )}

                  </BaseSelect>
                </BaseButtonsForm.Item>
              </BaseCol>

            }

            {/* {formDataTemp && formDataTemp!.firstName && <BaseCol xs={12} sm={8} md={12} xl={6}>


              <BaseButton type="primary" style={{ margin: '20px 20px' }} onClick={(e) => setFoundRecord(e)}>
                Set found record
              </BaseButton>

            </BaseCol>
            } */}
          </BaseRow>
          <BaseRow gutter={[10, 10]}>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="firstName"
                label={"First Name"}
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="lastName"
                label={"Last Name"}
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>




            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="dob"
                label={"Date Of Birth"}
                rules={[{ required: true, message: "Date Of Birth is required" }]}
              >
                <DayjsDatePicker format="L" size="large" />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="doctor_id"
                label={"Select a doctor"}
                hasFeedback
                rules={[{ required: true, message: "Doctor is required" }]}
              >
                <BaseSelect placeholder={"Please select a doctor"} onChange={(e: any) => getAvailabilityOfDoc(e)}>

                  {doctors && doctors.filter((x:any )=> x.status).map((item: any) =>

                    <Option value={item.id} disabled={!item.status}>{item.firstName} {item.lastName}</Option>

                  )}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>



            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="date"
                label={"Appointment Date"}
                rules={[{ required: true, message: "Appointment Date is required" }]}
              >
                {/* <DayjsDatePicker format="L" size="large" disabledDate={(current) => {
                  let customDate = dayjs().format('YYYY/MM/DD');
                  return current && current < dayjs(customDate, 'YYYY/MM/DD');
                }
                }
                /> */}




                <BaseSelect placeholder={"Please select a date"} onChange={(e: any) => setDate(e)}>

                  {availabilityDetails && Object.keys(availabilityDetails).map((key, idx, arr) => (

                    <Option value={key} >{key}</Option>

                  ))}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="time"
                label={"Time Of Appointment"}
                rules={[{ required: true, message: "Time Of Appointment is required" }]}
              >
                {/* <TimePicker format={timeFormat} /> */}


                <BaseSelect placeholder={"Please select a date"}

                >
                  {date && availabilityDetails && availabilityDetails[date] && (availabilityDetails[date] as string[]).map((t: string) =>
                    <Option value={t} disabled={
                      !checkIfAppointmentAvailable(date, t)

                    }>{t}</Option>

                  )}
                </BaseSelect>



              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="gender" label="Gender" valuePropName="checked">
                <BaseRadio.Group onChange={(event) => setValue(event.target.value)} value={value}>
                  <BaseRadio value={'Male'}>Male</BaseRadio>
                  <BaseRadio value={'Female'}>Female</BaseRadio>

                </BaseRadio.Group>
              </BaseButtonsForm.Item>


            </BaseCol>


          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('common.submit')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </Form>
      }
    </>
  );
};
