import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileNavData } from '@app/constants/profileNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ProfileNav.styles';
import { readUser } from '@app/services/localStorage.service';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const user = readUser();
  return (
    <S.Wrapper>

      {user.user_type == 2 ?
        profileNavData.map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            $isActive={`/profile/${item.href}` === location.pathname}
          >
            {t(item.name)}
          </S.Btn>

        ))
        :
        profileNavData.filter((el) => !el.externalOnly).map((item) => (
          <S.Btn
            key={item.id}
            icon={item.icon}
            type="text"
            color={item.color}
            onClick={() => navigate(item.href)}
            $isActive={`/profile/${item.href}` === location.pathname}
          >
            {t(item.name)}
          </S.Btn>

        ))
      }
    </S.Wrapper>
  );
};
