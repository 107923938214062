import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BasicTable } from '../BasicTable/BasicTable';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import ChartListCompo from './components/ChartListCompo';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { DownOutlined } from '@ant-design/icons';
import { ValidationFormVitalsAdd } from './components/Vitals/ValidationFormVitalsAdd';
import AddVitalsModal from './components/Vitals/AddVitalsModal';
import ClinicalNotesModalAdd from './components/Compliants/AddCompliantsModa';
import AddPrescriptionsModal from './components/Prescriptions/AddPrescriptionsModal';
import AddLabTestModal from './components/LabTests/AddLabTestModal';
import FilesModalAdd from './components/FilesTab/AddFilesModal';
import { createPatientDataAction, getPatientDataAction } from '@app/store/slices/patientDataSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { readUser } from '@app/services/localStorage.service';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { getAllDoctorsAction } from '@app/store/slices/userSlice';
import config from '@app/config';

const ManageChartsList: React.FC = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [patientDataArr, setPatientDataArr] = useState([]);
    const [patientDataId, setPatientDataId] = useState(0);
    let { id } = useParams();
    const [doctors, setDoctors] = useState([]);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDoctor, setSelectedDoctor] = useState(searchParams.get('doctor_id') ? Number(searchParams.get('doctor_id')) : null);
    let clinicDetails = readUser();
    if(clinicDetails!.role_id==config.roleIds.Doctor) clinicDetails!.id=clinicDetails!.clinic_id;

    const [loading, setLoading] = useState(true);
    console.log({ searchParams })

    useEffect(() => {

        const theInitializer = async () => {

            dispatch(getAllDoctorsAction(clinicDetails!.id))
                .unwrap()
                .then((resp: any) => {

                    setDoctors(resp);
                    setLoading(false);
                }).catch((err) => {
                    setLoading(false);
                    notificationController.error({ message: err.message });
                })
        }

        theInitializer();
    
            getPatientDataAll();
     
    }, []);


    const getPatientDataAll = async () => {
       
        let patient_id = Number(id);
        let clinic_id = clinicDetails!.id || 0;

        dispatch(getPatientDataAction({ patient_id: patient_id || 1, clinic_id: clinic_id }))
            .unwrap()
            .then((resp: any) => {
                console.log({resp})
                if (resp && !resp.error && !resp.Error) {

                    setPatientDataArr(resp);
                    console.log({resp})
                }


            }).catch((err) => {
                notificationController.error({ message: err.message });
            })

    }
    const positionItems = [
        {
            key: '1',
            label: <span style={{ cursor: 'pointer' }} onClick={() => setAddType('1')}>Vital Signs +</span>,
        },
        {
            key: '2',
            label: <span style={{ cursor: 'pointer' }} onClick={() => setAddType('2')}>Clinical Notes +</span>,
        },
        {
            key: '3',
            label: <span style={{ cursor: 'pointer' }} onClick={() => setAddType('3')}>Prescriptions +</span>,
        }, {
            key: '4',
            label: <span style={{ cursor: 'pointer' }} onClick={() => setAddType('4')}>Lab Tests +</span>,
        }, {
            key: '5',
            label: <span style={{ cursor: 'pointer' }} onClick={() => setAddType('5')}>Files +</span>,
        }
    ];
    const [addType, setAddType] = useState<any>('');
    console.log({ addType })

    console.log({ selectedDoctor })




    const handleCreatePatientData = async () => {
        if (!selectedDoctor) {

            notificationController.warning({ message: 'Please select a doctor' });
            return;
        }

        let args = {
            patient_id: Number(id),
            doctor_id: selectedDoctor,
            clinic_id: clinicDetails!.id
        }

        dispatch(createPatientDataAction(args))
            .unwrap()
            .then((resp: any) => {
                console.log({ resp });
                if (resp && !resp.error && !resp.Error) {

                    notificationController.success({ message: 'Data Saved' });
                    getPatientDataAll();

                }
                else {
                    notificationController.error({ message: resp && resp.message || "Something went wrong" });
                }


            }).catch((err: any) => {
                console.log({ err });
                notificationController.error({ message: err.message });
            })
    }
    return (
        <>
            {addType == '1' &&
                <AddVitalsModal
                    setAddType={setAddType}
                    addType={addType}
                    getPatientDataAll={getPatientDataAll}
                    setPatientDataId={setPatientDataId}
                    patientDataId={patientDataId}
                />
            }
            {addType == '2' &&
                <ClinicalNotesModalAdd
                    setAddType={setAddType}
                    addType={addType}
                    getPatientDataAll={getPatientDataAll}
                    setPatientDataId={setPatientDataId}
                    patientDataId={patientDataId}

                />
            }
            {addType == '3' &&
                <AddPrescriptionsModal
                    setAddType={setAddType}
                    addType={addType}
                    getPatientDataAll={getPatientDataAll}
                    setPatientDataId={setPatientDataId}
                    patientDataId={patientDataId}

                />
            }

            {addType == '4' &&
                <AddLabTestModal
                    setAddType={setAddType}
                    addType={addType}
                    getPatientDataAll={getPatientDataAll}
                    setPatientDataId={setPatientDataId}
                    patientDataId={patientDataId}
                />
            }

            {addType == '5' &&
                <FilesModalAdd
                    setAddType={setAddType}
                    addType={addType}

                />
            }

            {!loading &&
                <div style={{ marginBottom: '20px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ fontWeight: 'bold' }}>Manage Charts</h4>
                    {/* <BaseDropdown menu={{ items: positionItems }} trigger={['click']}> */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <BaseButtonsForm.Item
                            name="state"
                            label={"Doctor"}
                            hasFeedback
                            rules={[{ required: true, message: "State is required" }]}
                            initialValue={selectedDoctor}
                        >
                            <BaseSelect
                                defaultValue={selectedDoctor}
                                placeholder={"Please select a doctor"} style={{ width: '200px' }} onChange={(e: any) => setSelectedDoctor(e)} allowClear={true}>
                                {doctors && doctors.map((item: any) =>
                                    <Option title={item!.firstName + ' ' + item!.lastName} value={item.id}>{item!.firstName} {item!.lastName}</Option>
                                )}



                            </BaseSelect>
                        </BaseButtonsForm.Item>
                        <BaseButton onClick={() => handleCreatePatientData()} style={{ marginLeft: '10px' }}>
                            Create a new chart
                        </BaseButton>

                    </div>
                    {/* </BaseDropdown> */}
                </div>
            }
            {patientDataArr && patientDataArr.length==0 && <span>No patient history</span>}
            {patientDataArr && patientDataArr.length > 0 &&
                <ChartListCompo
                setAddType={setAddType}
                patientDataArr={patientDataArr}
                patientDataId={patientDataId}
                setPatientDataId={setPatientDataId}
                getPatientDataAll={getPatientDataAll}
                />
            }


        </>
    );
};

export default ManageChartsList;
