import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ValidationFormMyPatientEdit } from '@app/components/manage-my-patients/ValidationFormEdit/ValidationForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';
import PatientChartList from './ManageCharts/PatientChartList';

const EditMyPatients: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <PageTitle>Manage My Patients</PageTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4 style={{ fontWeight: 'bold' }}>Edit Patient</h4>
        <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>

      </div>
      < ValidationFormMyPatientEdit />
      <hr style={{ marginTop: '30px' }} />
      <div style={{ marginTop: '50px' }}>
        <PatientChartList

        />
      </div>
    </>
  );
};

export default EditMyPatients;
