import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormPrescriptionEdit } from './ValidationFormPrescriptionEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { PayCircleOutlined } from '@ant-design/icons';
import { ValidationFormPrescriptionAdd } from './ValidationFormPrescriptionsAdd';

interface EditPrescriptionsProps {
    setAddType: (editType: string) => void;
    addType: String,
    getPatientDataAll:any
    setPatientDataId:any
    patientDataId:any

}

const AddPrescriptionsModal: React.FC<EditPrescriptionsProps> = (props) => {
    const { setAddType, addType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={addType == '3'} onCancel={() => setAddType('')} onOk={()=>setAddType('') } footer={<></>}>

            <h3 style={{marginBottom:'20px',borderBottom:'1px solid grey'}}>Add New Prescriptions</h3>

            <ValidationFormPrescriptionAdd
                formRef={formRef}
                setAddType={setAddType}
                getPatientDataAll={props.getPatientDataAll}
                setPatientDataId={props.setPatientDataId}
                patientDataId={props.patientDataId}
                
            />
        </BaseModal>

    );
};

export default AddPrescriptionsModal;
