import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import MyDoctorsList from '@app/components/manage-my-doctors/list';

const ManageMyDoctors: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>Manage My Doctors</PageTitle>
      <MyDoctorsList />
    </>
  );
};

export default ManageMyDoctors;
