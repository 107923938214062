import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getFacilityByIdAction, getInternalUserByIdAction, getLabTestByIdAction, getMedicineByIdAction, updateFacilityAction, updateInternalUserAction, updateLabTestAction, updateMedicineAction } from '@app/store/slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import config from '@app/config';
import { readUser } from '@app/services/localStorage.service';
import { returnArea } from '@app/utils/utils';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};



export const ValidationFormLabTestsEdit: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const [value, setValue] = useState('Male')
  const clinicDetails = readUser();
  if (clinicDetails!.role_id == config.roleIds.Doctor) clinicDetails!.id = clinicDetails!.clinic_id;

  const { id } = useParams();


  useEffect(() => {





    dispatch(getLabTestByIdAction(Number(id)))
      .unwrap()
      .then((resp) => {

        console.log({ resp })

        console.log({ resp })
       
        setFormData(resp);

        setLoading(false);


      }).catch((err) => {
        notificationController.error({ message: err.message });
      })


  }, [])

  const onFinish = async (values: any) => {
    setLoading(true);
    setFieldsChanged(false);

    console.log(values);
    values.id = Number(id);
    dispatch(updateLabTestAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error) {
          notificationController.success({ message: t('common.success') });
          navigate(-1);
        }
        else {
          notificationController.error({ message: 'Failed to save info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  return (
    <div>

      {!isLoading && <BaseButtonsForm
        {...formItemLayout}
        isFieldsChanged={isFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        name="validateForm"
        initialValues={formData}
        footer={
          // <BaseRow gutter={[10, 10]}>
          //   <BaseCol xs={12} sm={8} md={12} xl={6}>
          //     <h3>Finish</h3></BaseCol>

          // </BaseRow>
          <></>
        }
        onFinish={onFinish}
      >


        <BaseRow gutter={[10, 10]}>


         

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="name"
              label={"Name"}
              rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item name="isActive" label="Status" valuePropName="checked" initialValue={true}>
              <BaseSwitch defaultChecked={true} />
            </BaseButtonsForm.Item>


          </BaseCol>
        </BaseRow>


        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </BaseButton>
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
      }
    </div>
  );
};
