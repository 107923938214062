import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getFacilityByIdAction, getInternalUserByIdAction, getOtherInfoByIdAction, updateFacilityAction, updateInternalUserAction, updateOtherInfoAction } from '@app/store/slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import config from '@app/config';
import { readUser } from '@app/services/localStorage.service';
import { returnArea } from '@app/utils/utils';
import { getOtherInfoById } from '@app/apis/user-api';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};



export const ValidationFormOtherInfoEdit: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const [image1, setImage1] = useState<any[]>([]);
  const [image2, setImage2] = useState<any[]>([]);

  const [aboutImage1, setAboutImage1] = useState<any[]>([]);
  const [aboutImage2, setAboutImage2] = useState<any[]>([]);

  const [siteLogo, setSiteLogo] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const [value, setValue] = useState('Male')
  const clinicDetails = readUser();
  if (clinicDetails!.role_id == config.roleIds.Doctor) clinicDetails!.id = clinicDetails!.clinic_id;

  const { id } = useParams();

  const normFile = (e: any, type: string) => {
    console.log({ e })
    if (e.file.status == 'done') {

      let imgArr = [{ name: e.file?.response?.file?.name, status: 'done', url: e.file?.response?.file?.url, uid: e.file?.response?.file?.file_id }]
      console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
      if (type == 'image1') setImage1(imgArr); else if (type == 'image2') setImage2(imgArr); else if (type == 'aboutImage1') setAboutImage1(imgArr); else if (type == 'aboutImage2') setAboutImage2(imgArr); else setSiteLogo(imgArr);
      // setFileId(e.file?.response?.file?.file_id);
    }
    if (Array.isArray(e.fileList)) {
      return e.fileList;
    }

    return e && e.fileList;
  };
  useEffect(() => {





    dispatch(getOtherInfoByIdAction(Number(id)))
      .unwrap()
      .then((resp) => {

        console.log({ resp })
        if (resp && !resp.error) {
          let temp = JSON.parse(resp.configData);

          setImage1(temp.image1 ? JSON.parse(JSON.stringify(temp.image1)) : []);
          setImage2(temp.image2 ? JSON.parse(JSON.stringify(temp.image2)) : []);
          setSiteLogo(temp.siteLogo ? JSON.parse(JSON.stringify(temp.siteLogo)) : []);


          setAboutImage1(temp.aboutImage1 ? JSON.parse(JSON.stringify(temp.aboutImage1)) : []);
          setAboutImage2(temp.aboutImage2 ? JSON.parse(JSON.stringify(temp.aboutImage2)) : []);

          temp.image1?.map((x: any) => x.url = config.API1Url + '/' + x.url)
          temp.image2?.map((x: any) => x.url = config.API1Url + '/' + x.url)
          temp.siteLogo?.map((x: any) => x.url = config.API1Url + '/' + x.url)
          temp.aboutImage1?.map((x: any) => x.url = config.API1Url + '/' + x.url)
          temp.aboutImage2?.map((x: any) => x.url = config.API1Url + '/' + x.url)

          console.log({ temp })
          setFormData(temp);
        }


        setLoading(false);


      }).catch((err) => {
        notificationController.error({ message: err.message });
      })


  }, [])

  const onFinish = async (values: any) => {
    setLoading(true);
    setFieldsChanged(false);

    values.image1 = image1;
    values.image2 = image2;
    values.siteLogo = siteLogo;
    values.aboutImage1 = aboutImage1;
    values.aboutImage2 = aboutImage2;

    console.log({ values })

    dispatch(updateOtherInfoAction({ configData: JSON.stringify(values), id: id }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error) {
          notificationController.success({ message: t('common.success') });
          navigate(0);
        }
        else {
          notificationController.error({ message: 'Failed to save info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })

  };

  console.log({ image1 });
  console.log({ image2 });
  console.log({ siteLogo });
  return (
    <div>

      {!isLoading && <BaseButtonsForm
        {...formItemLayout}
        isFieldsChanged={isFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        name="validateForm"
        initialValues={formData}
        footer={
          // <BaseRow gutter={[10, 10]}>
          //   <BaseCol xs={12} sm={8} md={12} xl={6}>
          //     <h3>Finish</h3></BaseCol>

          // </BaseRow>
          <></>
        }
        onFinish={onFinish}
      >

        <BaseRow gutter={[10, 10]}>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="heading1"
              label={"Heading 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="description1"
              label={"Description 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="subheading1"
              label={"Subheading 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="subdescription1"
              label={"Subdescription 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="subheading2"
              label={"Subheading 2"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="subdescription2"
              label={"Subdescription 2"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="siteLogo"
              label={"Site Logo"}
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please select a logo"
                }
              ]}
              getValueFromEvent={(e) => normFile(e, 'logo')}
            >
              <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" multiple={false} maxCount={1} showUploadList={{ showRemoveIcon: false }}>
                <BaseButton type="default" icon={<UploadOutlined />}>
                  {t('forms.validationFormLabels.clickToUpload')}
                </BaseButton>
              </BaseUpload>
            </BaseButtonsForm.Item>
          </BaseCol>




          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="image1"
              label={"Upload Image 1"}
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please select an image"
                }
              ]}
              getValueFromEvent={(e) => normFile(e, 'image1')}
            >
              <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" multiple={false} maxCount={1} showUploadList={{ showRemoveIcon: false }}>
                <BaseButton type="default" icon={<UploadOutlined />}>
                  {t('forms.validationFormLabels.clickToUpload')}
                </BaseButton>
              </BaseUpload>
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="image2"
              label={"Upload Image 2"}
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please select an image"
                }
              ]}
              getValueFromEvent={(e) => normFile(e, 'image2')}
            >
              <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" multiple={false} maxCount={1} showUploadList={{ showRemoveIcon: false }}>
                <BaseButton type="default" icon={<UploadOutlined />}>
                  {t('forms.validationFormLabels.clickToUpload')}
                </BaseButton>
              </BaseUpload>
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count1"
              label={"Count 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count1Title"
              label={"Count 1 Title "}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count2"
              label={"Count 2"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count2Title"
              label={"Count 2 Title "}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count3"
              label={"Count 3"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count3Title"
              label={"Count 3 Title "}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count4"
              label={"Count 4"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="count4Title"
              label={"Count 4 Title "}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="processTitle"
              label={"Process Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step1Title"
              label={"Step 1 Title "}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step1Description"
              label={"Step 1 Description"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step2Title"
              label={"Step 2 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step2Description"
              label={"Step 2 Description"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step3Title"
              label={"Step 3 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="step3Description"
              label={"Step 3 Description"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="panelEmail"
              label={"Panel Email"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="mapURL"
              label={"Map URL"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>



        </BaseRow>
        <hr style={{ marginBottom: '5vh' }} />

        <h4 style={{ fontWeight: 'bold', marginBottom: '5vh' }}>Contact Us Page</h4>
        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contactUsBody"
              label={"Contact Us Body"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact1Title"
              label={"Contact 1 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact1Content"
              label={"Contact 1 Content"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact2Title"
              label={"Contact 2 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact2Content"
              label={"Contact 2 Content"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>




          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact3Title"
              label={"Contact 3 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="contact3Content"
              label={"Contact 3 Content"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>

        </BaseRow>

















        <hr style={{ marginBottom: '5vh' }} />

        <h4 style={{ fontWeight: 'bold', marginBottom: '5vh' }}>About Us Page</h4>
        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="aboutUsBody1"
              label={"About Us Body 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="about1Title"
              label={"About 1 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="aboutUsBody2"
              label={"About Us Body 2"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput.TextArea rows={4} />
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="about2Title"
              label={"About 2 Title"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>




          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="aboutImage1"
              label={"About Image 1"}
              valuePropName="fileList"
              rules={[
                {
                  required: true,
                  message: "Please select an image"
                }
              ]}
              getValueFromEvent={(e) => normFile(e, 'aboutImage1')}
            >
              <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" multiple={false} maxCount={1} showUploadList={{ showRemoveIcon: false }}>
                <BaseButton type="default" icon={<UploadOutlined />}>
                  {t('forms.validationFormLabels.clickToUpload')}
                </BaseButton>
              </BaseUpload>
            </BaseButtonsForm.Item>
          </BaseCol>







        </BaseRow>



        <hr style={{ marginBottom: '5vh' }} />

        <h4 style={{ fontWeight: 'bold', marginBottom: '5vh' }}>Footer Section</h4>
        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="text1"
              label={"Text 1"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="text2"
              label={"Text 2"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>


          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="text3"
              label={"Text 3"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>



          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item
              name="text4"
              label={"Text 4"}
            // rules={[{ required: true, message: "Name is required" }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

        </BaseRow>

        <BaseRow gutter={[10, 10]}>
          <BaseCol xs={12} sm={8} md={12} xl={6}>
            <BaseButtonsForm.Item>
              <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </BaseButton>
            </BaseButtonsForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
      }
    </div>
  );
};
