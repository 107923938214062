import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { EditOutlined, FileAddOutlined } from '@ant-design/icons';

import { BaseButton } from '../../../common/BaseButton/BaseButton';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Modal, Row, Tooltip } from 'antd';
import EditVitalsModal from './Vitals/EditVitalsModal';
import CLinicalNotesModal from './Compliants/EditCompliantsModal';
import EditPrescriptionsModal from './Prescriptions/EditPrescriptionsModal';
import EditLabTestModal from './LabTests/EditLabTestsModal';
import FilesModalEdit from './FilesTab/EditFilesModal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';

const ChartListCompo: React.FC<any> = (props) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [editType, setEditType] = useState('');
    const [currentData, setCurrentData] = useState(null);
    const payload1 = [
        {
            dataDate: "14/10/2023",
            vitalSigns: {
                weight: 77,
                bloodPressure: 'lower - 80 , \n \n higher - 110',
                pulse: 89,
                temperature: 36,
                respRate: 97
            },
            prescriptions: [
                {
                    drugName: 'Paracip 500',
                    tabPerDose: 1,
                    frequency: "twice per day",
                    duration: 4
                }
            ],
            compliants: {
                compliants: "Low BP, Continuous Fever, weakness and fatigue, Low appetite",
                observations: "yellow skin, pale hands",
                notes: "Observation"
            },
            files: [
                { name: "CBC Count test.pdf", url: "" },
                { name: "LFT test.pdf", url: "" }
            ],
            labTests: [
                {
                    testName: "LFT Test",
                    instruction: "empty stomach needed"
                }
            ]
        },
        {
            dataDate: "18/10/2023",
            vitalSigns: {
                weight: 77,
                bloodPressure: 'lower - 80 , \nhigher - 110',
                pulse: 89,
                temperature: 36,
                respRate: 97
            },
            prescriptions: [
                {
                    drugName: 'Paracip 500',
                    tabPerDose: 1,
                    frequency: "twice per day",
                    duration: 4
                }
            ],
            compliants: {
                compliants: "Low BP, Continuous Fever, weakness and fatigue, Low appetite",
                observations: "yellow skin, pale hands",
                notes: "Observation"
            },
            files: [
                { name: "CBC Count test.pdf", url: "" },
                { name: "LFT test.pdf", url: "" }
            ],
            labTests: [
                {
                    testName: "LFT Test",
                    instruction: "empty stomach needed"
                }
            ]
        },
        {
            dataDate: "22/10/2023",
            vitalSigns: {
                weight: 77,
                bloodPressure: 'lower - 80 , higher - 110',
                pulse: 89,
                temperature: 36,
                respRate: 97
            },
            prescriptions: [
                {
                    drugName: 'Paracip 500',
                    tabPerDose: 1,
                    frequency: "twice per day",
                    duration: 4
                }
            ],
            compliants: {
                compliants: "Low BP, Continuous Fever, weakness and fatigue, Low appetite",
                observations: "yellow skin, pale hands",
                notes: "Observation"
            },
            files: [
                { name: "CBC Count test.pdf", url: "" },
                { name: "LFT test.pdf", url: "" }
            ],
            labTests: [
                {
                    testName: "LFT Test",
                    instruction: "empty stomach needed"
                }
            ]
        },
        {
            dataDate: "23/10/2023",
            vitalSigns: {
                weight: 77,
                bloodPressure: 'lower - 80 , higher - 110',
                pulse: 89,
                temperature: 36,
                respRate: 97
            },
            prescriptions: [
                {
                    drugName: 'Paracip 500',
                    tabPerDose: 1,
                    frequency: "twice per day",
                    duration: 4
                }
            ],
            compliants: {
                compliants: "Low BP, Continuous Fever, weakness and fatigue, Low appetite",
                observations: "yellow skin, pale hands",
                notes: "Observation"
            },
            files: [
                { name: "CBC Count test.pdf", url: "" },
                { name: "LFT test.pdf", url: "" }
            ],
            labTests: [
                {
                    testName: "LFT Test",
                    instruction: "empty stomach needed"
                }
            ]
        }
    ]
    const payload = props.patientDataArr as any[];
    const theme = useAppSelector((state) => state.theme.theme);

    console.log({ theme })
    const onEdit = (rowId: number, editType: string) => {


        setEditType(editType);
    }

    return (
        <>
            {editType == 'vitals' &&
                <EditVitalsModal
                    setEditType={setEditType}
                    editType={editType}
                    currentData={currentData}
                    getPatientDataAll={props.getPatientDataAll}
                />
            }

            {editType == 'clinical_notes' &&
                <CLinicalNotesModal
                    setEditType={setEditType}
                    editType={editType}
                    currentData={currentData}
                    getPatientDataAll={props.getPatientDataAll}
                />
            }

            {editType == 'prescriptions' &&
                <EditPrescriptionsModal
                    setEditType={setEditType}
                    editType={editType}
                    currentData={currentData}
                    getPatientDataAll={props.getPatientDataAll}
                    patientDataId={props.patientDataId}
                />
            }
            {editType == 'lab_tests' &&
                <EditLabTestModal
                    setEditType={setEditType}
                    editType={editType}
                    currentData={currentData}
                    getPatientDataAll={props.getPatientDataAll}
                    patientDataId={props.patientDataId}
                />
            }

            {editType == 'files' &&
                <FilesModalEdit
                    setEditType={setEditType}
                    editType={editType}

                />
            }
            {payload && payload.map((card: any, idx: number) =>
                <BaseCard className='patient-chart' padding="0 0 1.875rem" style={{ marginBottom: 30, height: 'auto', overflow: 'auto' }} title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ color: theme == 'dark' ? 'white' : 'blue ' }}>{moment(card.createdAt).format('DD-MMM-YYYY')}</span>

                        <div style={{ fontSize: '0.8em' }}><span>Report by:</span> <span style={{ color: theme == 'dark' ? 'white' : 'blue ' }} >Dr. {card.doctor && card.doctor.firstName} {card.doctor && card.doctor.lastName}</span></div>
                    </div>

                }>
                    {/* <hr /> */}

                    <div style={{ padding: '10px 20px' }}>
                        {true &&

                            <div style={{
                                // borderBottom: '3px solid grey',
                                padding: '20px 20px', backgroundColor: theme == 'dark' ? '#004080' : '#FAF9F6 ',
                                borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '30px'
                            }}>
                                <Row>


                                    <h2 style={{ fontWeight: 800 }}>Vital Signs</h2>

                                    {card.vitals && <Tooltip title="Edit"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { onEdit(idx, 'vitals'); props.setPatientDataId(card.id); setCurrentData(card.vitals) }}><EditOutlined /></span></Tooltip>}
                                    {!card.vitals && <Tooltip title="Add vitals"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { props.setAddType('1'); props.setPatientDataId(card.id) }}><FileAddOutlined /></span></Tooltip>}
                                </Row>
                                {card.vitals &&
                                    <div style={{ overflow: 'auto' }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', borderBottom: '0.5px dashed silver', paddingBottom: '10px', marginTop: '30px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Weight(kg)</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> B.P.(mmHg)</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Pulse(Heart beats/min)</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Temperature(°C)</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Resp. Rate(Breaths/min)</span>
                                        </div>
                                        <div style={{ flexDirection: 'row', display: 'flex', marginTop: '20px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', }}> {card.vitals.weight}</span>
                                            <span style={{ flex: 1, minWidth: '150px', }}> {card.vitals.bp}</span>
                                            <span style={{ flex: 1, minWidth: '150px', }}> {card.vitals.pulse}</span>
                                            <span style={{ flex: 1, minWidth: '150px', }}> {card.vitals.temperature}</span>
                                            <span style={{ flex: 1, minWidth: '150px', }}> {card.vitals.respRate}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {true &&

                            <div style={{
                                // borderBottom: '3px solid grey',
                                padding: '20px 20px', backgroundColor: theme == 'dark' ? '#004080' : '#FAF9F6 ',
                                borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '30px'
                            }}>
                                <Row>
                                    <h2 style={{ fontWeight: 800 }}>Clinical Notes</h2>
                                    {card.clinicalNotes && <Tooltip title="Edit"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { onEdit(idx, 'clinical_notes'); props.setPatientDataId(card.id); setCurrentData(card.clinicalNotes) }}><EditOutlined /></span></Tooltip>}
                                    {!card.clinicalNotes && <Tooltip title="Add notes"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { props.setAddType('2'); props.setPatientDataId(card.id) }}><FileAddOutlined /></span></Tooltip>}
                                </Row>

                                {card.clinicalNotes &&
                                    <div style={{ overflow: 'auto' }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', borderBottom: '0.5px dashed silver', paddingBottom: '10px', marginTop: '30px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Compliants</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Observations</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Notes</span>

                                        </div>
                                        <div style={{ flexDirection: 'row', display: 'flex', marginTop: '20px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}><pre>{card.clinicalNotes.complaints}</pre></span>
                                            <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> <pre>{card.clinicalNotes.observations}</pre></span>
                                            <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> <pre>{card.clinicalNotes.notes}</pre></span>

                                        </div>
                                    </div>
                                }
                            </div>
                        }


                        {true &&

                            <div style={{
                                // borderBottom: '3px solid grey',
                                padding: '20px 20px', backgroundColor: theme == 'dark' ? '#004080' : '#FAF9F6 ',
                                borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '30px'
                            }}>
                                <Row>
                                    <h2 style={{ fontWeight: 800 }}>Prescriptions</h2>

                                    {card.prescription && <Tooltip title="Edit"> <span style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={() => {
                                            onEdit(idx, 'prescriptions'); props.setPatientDataId(card.id); setCurrentData(card.prescription
                                                && card.prescription
                                                    .prescriptionDetails)
                                        }}>
                                        <EditOutlined /></span></Tooltip>}
                                    {!card.prescription && <Tooltip title="Add prescription"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { props.setAddType('3'); props.setPatientDataId(card.id) }}><FileAddOutlined /></span></Tooltip>}

                                </Row>
                                {card.prescription
                                    && card.prescription
                                        .prescriptionDetails && card.prescription.prescriptionDetails
                                    &&
                                    <div style={{ overflow: 'auto' }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', borderBottom: '0.5px dashed silver', paddingBottom: '10px', marginTop: '30px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Drug Name</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Dosage & Frequency</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Duration</span>

                                        </div>

                                        {card.prescription.prescriptionDetails.map((item: any) =>

                                            <div style={{ flexDirection: 'row', display: 'flex', marginTop: '20px', justifyContent: 'flex-start' }}>

                                                <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> {item.drugName}</span>
                                                <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> {item.dosage} - {item.frequency}</span>
                                                <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> {item.duration}</span>

                                            </div>

                                        )}


                                    </div>

                                }

                            </div>
                        }

                        {true &&

                            <div style={{
                                // borderBottom: '3px solid grey',
                                padding: '20px 20px', backgroundColor: theme == 'dark' ? '#004080' : '#FAF9F6 ',
                                borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '30px'
                            }}>
                                <Row>
                                    <h2 style={{ fontWeight: 800 }}>Lab Tests</h2>
                                    {card.labTests && <Tooltip title="Edit"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {onEdit(idx, 'lab_tests');; props.setPatientDataId(card.id);
                                setCurrentData(card.labTests && card.labTests.labTestsDetails && card.labTests.labTestsDetails)}}><EditOutlined /></span></Tooltip>}
                                    {!card.labTests && <Tooltip title="Add lab tests"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => {props.setAddType('4'); props.setPatientDataId(card.id);}}><FileAddOutlined /></span></Tooltip>}


                                </Row>
                                {card.labTests && card.labTests.labTestsDetails && card.labTests.labTestsDetails &&
                                    <div style={{ overflow: 'auto' }}>
                                        <div style={{ flexDirection: 'row', display: 'flex', borderBottom: '0.5px dashed silver', paddingBottom: '10px', marginTop: '30px', justifyContent: 'flex-start' }}>

                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Test Name</span>
                                            <span style={{ flex: 1, minWidth: '150px', fontWeight: 'bold' }}> Instructions (if any)</span>


                                        </div>
                                        {card.labTests.labTestsDetails.map((item: any) =>
                                            <div style={{ flexDirection: 'row', display: 'flex', marginTop: '20px', justifyContent: 'flex-start' }}>

                                                <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> {item.testName}</span>
                                                <span style={{ flex: 1, minWidth: '150px', wordWrap: 'break-word' }}> {item.instructions}</span>


                                            </div>
                                        )}

                                    </div>

                                }

                            </div>
                        }

                        {/* {card.files &&

                            <div style={{
                                // borderBottom: '3px solid grey',
                                padding: '20px 20px', backgroundColor: theme == 'dark' ? '#004080' : '#FAF9F6 ',
                                borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '30px'
                            }}>
                                <Row>
                                    <h2 style={{fontWeight:800}}>Files</h2>

                                    <Tooltip title="Edit"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => onEdit(idx, 'files')}><EditOutlined /></span></Tooltip>
                                    <Tooltip title="Add files"> <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => props.setAddType('5')}><FileAddOutlined /></span></Tooltip>


                                </Row>
                                {card.files && card.files.map((item: any) =>
                                    <>

                                        <div style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>

                                            <a style={{ flex:1,minWidth:'150px', wordWrap: 'break-word' }}> {item.name}</a>
                                        </div>

                                    </>

                                )}

                            </div>
                        } */}

                    </div>


                </BaseCard>
            )}




        </>
    );
};

export default ChartListCompo;
