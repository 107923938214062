import axios from "@app/services/axios";




export const getClinicAppointments = (id: number): Promise<any> =>
    axios.get<any>('/appointment/clinic/' + id).then((res: any) => {

        return res.data;

    });
export const getDoctorAppointments = (id: number): Promise<any> =>
    axios.get<any>('/appointment/doctor/' + id).then((res: any) => {

        return res.data;

    });


export const getAppointmentById = (id: number): Promise<any> =>
    axios.get<any>('/appointment/' + id).then((res: any) => {

        return res.data;

    });


export const getAppointmentByMobile = (params: any): Promise<any> =>

    axios.get<any>('/appointment/phone/' + params.phone + '/' + params.clinic_id).then((res: any) => {

        console.log({params})
        return res.data;

    });


export const martAppointment = (id: number, payload: any): Promise<any> =>
    axios.put<any>('/appointment/complete/' + id, payload).then((res: any) => {

        return res.data;

    });

export const createAppointment = (payload: any): Promise<any> =>
    axios.post<any>('/appointment', payload).then((res: any) => {

        return res.data;

    });

export const updateAppointment = (id: number, payload: any): Promise<any> =>
    axios.put<any>('/appointment/' + id, payload).then((res: any) => {

        return res.data;

    });