import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { createInternalUser, getAdminDashboardInfo, getAllClients, getAllClinics, getAllDoctors, getAllFacilities, getAllInternalUsers, getAllLabTests, getAllMedicines, getAllSpecialities, getAllTestimonials, getAvailabilitySlotsOfDocInfo, getClinicDashboardInfo, getFacilityById, getInternalUserById, getLabTestById, getMedicineById, getMinimalClinicDetailById, getOtherInfoById, getStatesTree, getTestimonialsById, saveFacility, saveLabTest, saveMedicine, saveTestimonials, SendInvitationEmails, updateAvailabilityClinic, updateExternalUserOtherInfo, updateFacility, updateInternalUser, updateLabTest, updateMedicine, updateOtherInfo, updateTestimonials } from '@app/apis/user-api';

export interface UserState {
  user: UserModel | null;
  statesTree: any
}

const initialState: UserState = {
  user: readUser(),
  statesTree: []
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  console.log({ newUser })
  persistUser(newUser);

  return {
    payload: newUser,
  };
});
export const setStatesTree = createAction<PrepareAction<any>>('user/setStatesTree', (data) => {
  console.log({ data })
  return {
    payload: data,
  };
});



export const getStatesTreeAction = createAsyncThunk('user/getStatesTreeAction', async (_, { dispatch }) =>
  getStatesTree().then(async (res: any) => {

    console.log({ res })
    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const createInternalUserAction = createAsyncThunk('user/createInternalUser', async (type: any, { dispatch }) =>
  createInternalUser(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateInternalUserAction = createAsyncThunk('user/updateInternalUser', async (type: any, { dispatch }) =>
  updateInternalUser(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateExternalUserOtherInfoAction = createAsyncThunk('user/updateExternalUserOtherInfoAction', async (type: any, { dispatch }) =>
updateExternalUserOtherInfo(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getInternalUserByIdAction = createAsyncThunk('users/getSingle', async (id: number, { dispatch }) =>
  getInternalUserById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAllInternalUsersAction = createAsyncThunk('users/getALL', async (id: number, { dispatch }) =>
  getAllInternalUsers(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getAllClinicsAction = createAsyncThunk('clinics/getALL', async (id: number, { dispatch }) =>
  getAllClinics().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);
export const getAllDoctorsAction = createAsyncThunk('doctors/getALL', async (clinicId: number) =>
  getAllDoctors(clinicId).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAllClientsAction = createAsyncThunk('clients/getALL', async (clinicId: number) =>
  getAllClients(clinicId).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);
export const getAllSpecialitiesAction = createAsyncThunk('specialities/getALL', async (id: number, { dispatch }) =>
  getAllSpecialities().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(getStatesTreeAction.fulfilled, (state, action) => {
      console.log('inside treeee case');
      state.statesTree = action.payload;
    });
  },
});




/**
 * FACILITIES ACTIONS HERE
 */


export const createFacilityAction = createAsyncThunk('user/createFacilityAction', async (type: any, { dispatch }) =>
  saveFacility(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateFacilityAction = createAsyncThunk('user/updateFacilityAction', async (payload: any, { dispatch }) =>
  updateFacility(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getFacilityByIdAction = createAsyncThunk('users/getFacilityByIdAction', async (id: number, { dispatch }) =>
  getFacilityById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAllFacilityAction = createAsyncThunk('users/getAllFacilityAction', async (_, { dispatch }) =>
  getAllFacilities().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


/**
 * MEDICINES ACTIONS HERE
 */


export const createMedicineAction = createAsyncThunk('user/createMedicineAction', async (type: any, { dispatch }) =>
  saveMedicine(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateMedicineAction = createAsyncThunk('user/updateMedicineAction', async (payload: any, { dispatch }) =>
  updateMedicine(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getMedicineByIdAction = createAsyncThunk('users/getMedicineByIdAction', async (id: number, { dispatch }) =>
  getMedicineById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAllMedicinesAction = createAsyncThunk('users/getAllMedicinesAction', async (_, { dispatch }) =>
  getAllMedicines().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


// END OF MEDICINE ACTIONS




/**
 * Lab Tests ACTIONS HERE
 */


export const createLabTestAction = createAsyncThunk('user/createLabTestAction', async (type: any, { dispatch }) =>
  saveLabTest(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateLabTestAction = createAsyncThunk('user/updateLabTestAction', async (payload: any, { dispatch }) =>
  updateLabTest(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getLabTestByIdAction = createAsyncThunk('users/getLabTestByIdAction', async (id: number, { dispatch }) =>
  getLabTestById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAlLabTestsAction = createAsyncThunk('users/getAlLabTestsAction', async (_, { dispatch }) =>
  getAllLabTests().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


// END OF Lab Tests ACTIONS












export const updateOtherInfoAction = createAsyncThunk('user/updateOtherInfoAction', async (payload: any, { dispatch }) =>
  updateOtherInfo(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getOtherInfoByIdAction = createAsyncThunk('users/getOtherInfoByIdAction', async (id: number, { dispatch }) =>
  getOtherInfoById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);





/**
 * TESTIMONIALS ACTIONS HERE
 */


export const createTestimonialAction = createAsyncThunk('user/createTestimonialAction', async (type: any, { dispatch }) =>
  saveTestimonials(type).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const updateTestimonialAction = createAsyncThunk('user/updateTestimonialAction', async (payload: any, { dispatch }) =>
  updateTestimonials(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getTestimonialByIdAction = createAsyncThunk('users/getTestimonialByIdAction', async (id: number, { dispatch }) =>
  getTestimonialsById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getAllTestimonialAction = createAsyncThunk('users/getAllTestimonialAction', async (_, { dispatch }) =>
  getAllTestimonials().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getMinimalClinicDetailByIdAction = createAsyncThunk('users/minimalDetail', async (id: number, { dispatch }) =>
  getMinimalClinicDetailById(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const SendInvitationEmailsAction = createAsyncThunk('user/SendInvitationEmails', async (payload: any, { dispatch }) =>
  SendInvitationEmails(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const getAdminDashboardInfoAction = createAsyncThunk('users/adminDashboardInfo', async (_, { dispatch }) =>
  getAdminDashboardInfo().then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);

export const getClinicDashboardInfoAction = createAsyncThunk('users/clinicDashboardInfo', async (id: number, { dispatch }) =>
  getClinicDashboardInfo(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);
export const getAvailabilitySlotsOfDoInfoAction = createAsyncThunk('users/getAvailabilitySlotsOfDocnfo', async (id: number, { dispatch }) =>
  getAvailabilitySlotsOfDocInfo(id).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export const updateAvailabilityClinicAction = createAsyncThunk('user/updateAvailabilityClinic', async (payload: any, { dispatch }) =>
  updateAvailabilityClinic(payload).then(async (res: any) => {

    console.log({ res })

    return res;

  }).catch((err) => {
    console.log({ err });
    if (err?.response?.data?.message) {
      throw err?.response?.data?.message
    }
    else throw err;

  })
);


export default userSlice.reducer;
