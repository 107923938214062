import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import config from '@app/config';
import imgDefault from '../../../../assets/no-image.png'
interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  console.log({ profileData })
  const { t } = useTranslation();
let img=profileData && profileData.image && profileData.image.url ? config.API1Url +'/' + profileData.image.url : imgDefault;
console.log({img})
  return profileData ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={img} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.userName}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper> */}
      {/* <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};
