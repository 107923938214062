import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableDataUAppointments, Pagination } from 'api/clinicsGet.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useNavigate } from "react-router-dom";
import { getClinicAppointmentsAction } from '@app/store/slices/appointmentSlice';
import { readUser } from '@app/services/localStorage.service';
import { useAppDispatch } from '@app/hooks/reduxHooks';
const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
};

export const AppointmentsTable: React.FC<any> = () => {
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  let navigate = useNavigate();
  const clinicDetails = readUser();
  const dispatch = useAppDispatch();

  useEffect(() => {

    dispatch(getClinicAppointmentsAction(clinicDetails!.id))
      .unwrap()
      .then((resp: any) => {
        console.log({ resp });
        setTableData({ ...tableData, data: resp });

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })

  }, []);

  // const fetch = useCallback(
  //   (pagination: Pagination) => {
  //     setTableData((tableData) => ({ ...tableData, loading: true }));
  //     getBasicTableDataUAppointments(pagination).then((res) => {
  //       if (isMounted.current) {
  //         setTableData({ data: res.data, pagination: res.pagination, loading: false });
  //       }
  //     });
  //   },
  //   [isMounted],
  // );

  // useEffect(() => {
  //   fetch(initialPagination);
  // }, [fetch]);

  // const handleTableChange = (pagination: Pagination) => {
  //   fetch(pagination);
  // };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      render: (text: string,record:any) => <span>{record?.firstName + ' ' + record.lastName}</span>,
    },
    // {
    //   title: t('common.age'),
    //   dataIndex: 'age',
    //   sorter: (a: BasicTableRow, b: BasicTableRow) => a.age - b.age,
    //   showSorterTooltip: false,
    // },
    {
      title: t('common.phone'),
      dataIndex: 'phone',
    },
    {
      title: "Time",
      dataIndex: 'time',
    },
    // {
    //   title: "Doctor",
    //   dataIndex: 'doctor',
    // },

  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={tableData.data}
      pagination={tableData.pagination}
      loading={tableData.loading}
      // onChange={handleTableChange}
      scroll={{ x: 800 }}
      bordered
    />
  );
};
