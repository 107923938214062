import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormCompliantsEdit } from './ValidationFormNotesEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

interface EditVitalsProps {
    setEditType: (editType: string) => void;
    editType: String,
    currentData: any
    getPatientDataAll: any
}

const CLinicalNotesModal: React.FC<EditVitalsProps> = (props) => {
    const { setEditType, editType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    const [compliant, editCompliant] = useState(props?.currentData?.complaints);
    const [observations, editObservations] = useState(props?.currentData?.observations);
    const [notes, editNotes] = useState(props?.currentData?.notes);
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={editType == 'clinical_notes'} onCancel={() => setEditType('')} onOk={() => setEditType('')} footer={<></>}>

            <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Clinical Notes</h3>

            <ValidationFormCompliantsEdit
                formRef={formRef}
                compliant={compliant}
                editCompliant={editCompliant}
                observations={observations}
                editObservations={editObservations}
                notes={notes}
                editNotes={editNotes}
                currentData={props.currentData}
                setEditType={props.setEditType}
                getPatientDataAll={props.getPatientDataAll}
            />
        </BaseModal>

    );
};

export default CLinicalNotesModal;
