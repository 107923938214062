import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormFilesEdit } from './ValidationFormFilesEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { ValidationFormFilesAdd } from './ValidationFormFilesAdd';

interface FilesModalProps {
    setAddType: (addType: string) => void;
    addType: String
}

const FilesModalAdd: React.FC<FilesModalProps> = (props) => {
    const { addType, setAddType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    const [compliant, editCompliant] = useState('');
    const [observations, editObservations] = useState('');
    const [notes, editNotes] = useState('');
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={addType == '5'} onCancel={() => setAddType('')} onOk={() => setAddType('')}>

            <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Add New Files</h3>

            <ValidationFormFilesAdd
                formRef={formRef}
                compliant={compliant}
                editCompliant={editCompliant}
                observations={observations}
                editObservations={editObservations}
                notes={notes}
                editNotes={editNotes}
            />
        </BaseModal>

    );
};

export default FilesModalAdd;
