import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ClinicsList from '@app/components/manage-clinics/list';
import AddClinic from '@app/components/manage-clinics/add-clinic';
import { ValidationFormClinicAdd } from '@app/components/manage-clinics/ValidationFormAdd/ValidationForm';
import { Card } from 'antd';
import loginBackground from '@app/assets/images/login-bg.webp';
import { getMinimalClinicDetailByIdAction, getStatesTreeAction } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { ValidationFormDoctorAdd } from '@app/components/manage-my-doctors/ValidationFormAdd/ValidationForm';
import { useSearchParams } from 'react-router-dom';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import config from '@app/config';
const DoctorOnboardPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [clinicInfoOuter, setClinicInfoOuter] = useState<any>({});
    const [searchParams, setSearchParams] = useSearchParams();
    let outerClinicId = Number(searchParams.get('clinicId'));
    useEffect(() => {

        dispatch(getStatesTreeAction())
            .unwrap()
            .then((resp) => {


            }).catch((err) => {
                notificationController.error({ message: err.message || 'Something went wrong' });
            })


        dispatch(getMinimalClinicDetailByIdAction(outerClinicId))
            .unwrap()
            .then((resp) => {

                setClinicInfoOuter(resp);

            }).catch((err) => {
                notificationController.error({ message: err.message });
            })


    }, [])
    return (
        <div className='asm' style={{ background: `url(${loginBackground})`, backgroundSize: 'repeat' }}>
            <PageTitle>{'Doctor Onboard'}</PageTitle>
            <div style={{ width: '90%', margin: 'auto', padding: '30px 30px' }}>

                <Card title='Doctor Onboard'>
                    <div style={{ display: 'flex',alignItems:'center',padding:'20px 5px' }}
                    >
                        <img src={config.API1Url + '/' + clinicInfoOuter.image?.url} alt="Nepal CLinics" width={48} height={48} />
                        <h2 style={{marginLeft:'10px'}}>{clinicInfoOuter?.firstName} {clinicInfoOuter?.lastName}</h2>
                    </div>

                    <span>Please fill out the following details to register as a doctor for {clinicInfoOuter?.firstName} {clinicInfoOuter?.lastName}</span>
                    <hr />
                    <ValidationFormDoctorAdd outer={true} />
                </Card>
            </div>
        </div>
    );
};

export default DoctorOnboardPage;
