import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getLabTestsByNameAction, updateLabTestAction } from '@app/store/slices/patientDataSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Select, Spin } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormLabTestEdit: React.FC<any> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const dispatch = useAppDispatch();
  const [labTests, setLabTests] = useState<any[]>(props?.currentData);
  const [allLabTests, setAllLabTests] = useState<any[]>([]);

  console.log({ labTests })

  const onFinish = async (values = {}) => {
    setLoading(true);
    dispatch(updateLabTestAction({ id: props.patientDataId, payload: labTests }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          notificationController.success({ message: t('common.success') });
          props.setEditType('');
          props.getPatientDataAll();
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })
  };


  console.log('wewewewewewe', props.payload);
  const onUserInput = (e: string) => {

    console.log(e)
    if (e.length < 3) return;
    dispatch(getLabTestsByNameAction({ name: e }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          setAllLabTests(resp);
        }
        else {
          notificationController.error({ message: 'Failed to get labtests info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })
  }

  const handleOnChange = (val: string, type: string, idx: number) => {
    console.log({ val })
    let _labTests = [...labTests];

    _labTests[idx][type] = val;

    setLabTests(_labTests);

  }
  return (


    <BaseButtonsForm
      ref={props.formRef}
      // style={{ pointerEvents: 'none', opacity: '0.7' }}
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      // initialValues={{
      //   'input-number': 3,
      //   'checkbox-group': ['A', 'B'],
      //   rate: 3.5,
      // }}
      footer={
        <></>
      }
      onFinish={onFinish}
    >

      {labTests?.map((item, index) =>


        <BaseRow gutter={[10, 10]}>


          <BaseCol xs={12} sm={8} md={12} xl={8}>
            <BaseButtonsForm.Item
              name={"testName" + index}
              label={"Test Name"}
              initialValue={item.testName}
            // rules={[{ required: true, message: "Test Name is required" }]}
            >
              <BaseInput disabled defaultValue={item.testName} />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={12} sm={8} md={12} xl={8}>
            <BaseButtonsForm.Item

              name={"instructions" + index}
              label={"Instructions"}
              initialValue={item.instructions}
            // rules={[{ required: true, message: "Instructions is required" }]}
            >
              <BaseInput defaultValue={item.instruction} onChange={(e: any) => handleOnChange(e.target.value, 'instructions', index)} />
            </BaseButtonsForm.Item>
          </BaseCol>


        </BaseRow>

      )}


      <BaseRow gutter={[10, 10]}>


        <BaseButtonsForm.Item
          style={{ width: '300px' }}
          // name="addDrug"
          label={'Select Drug To Add'}
          hasFeedback
        // rules={[{ required: true, message: t('forms.validationFormLabels.countryError') }]}
        >
          <Select
            onChange={(e:any) => { setLabTests([...labTests, { testName: e!.value }]); setValue(null); }} value={value}

            showSearch
            labelInValue
            filterOption={false}


            placeholder="Enter lab test name to search"
            onSearch={(evt) => onUserInput(evt)}
            // options={users}
            notFoundContent={isLoading ? <Spin size="small" /> : <span>No results</span>}
          >
            {allLabTests && (allLabTests || []).length > 0 && allLabTests.map(function (item: any) {
              return (
                <Select.Option key={item.name} value={item.name}>
                  {item.Brand_Name}
                </Select.Option>
              );
            })}

          </Select>
        
        </BaseButtonsForm.Item>
      </BaseRow>

      <BaseRow gutter={[10, 10]}>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow>

    </BaseButtonsForm>

  );
};
