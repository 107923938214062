import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { updateNotesAction } from '@app/store/slices/patientDataSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const ValidationFormCompliantsEdit: React.FC<any> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('Male');
  const dispatch=useAppDispatch();
  const onFinish = async (values:any) => {
    setLoading(true);

    values.patient_data_id = props.currentData.patient_data_id;
    console.log(values);

    // return;
    dispatch(updateNotesAction({ id: props.currentData.id, payload: values }))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp && !resp.error && !resp.Error) {
          notificationController.success({ message: t('common.success') });
          props.setEditType('');
          props.getPatientDataAll();
        }
        else {
          notificationController.error({ message: 'Failed to save patient info' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })
  };
  console.log('props.formRef', props.formRef)



  const handleInput = (event: any, type: string) => {
    let previousLength = 0;

    console.log({ event });
    switch (type) {

      case 'compliant':
        previousLength = props.compliant || "".length;
        break;
      case 'observations':
        previousLength = props.observations || "".length;
        break;

      case 'notes':
        previousLength = props.notes || "".length;
        break;
    }
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }

    previousLength = newLength;
  }



  return (

    <BaseButtonsForm
      ref={props.formRef}

      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      initialValues={{ complaints: props.compliant, notes: props.notes, observations: props.observations }}
      footer={
        <></>
      }
      onFinish={onFinish}
    >

      <BaseRow gutter={[10, 10]}>



        <BaseCol xs={12} sm={8} md={12} xl={24}>
          <BaseButtonsForm.Item
            name="complaints"
            label={"Compliants"}

            // rules={[{ required: true, message: "Compliants is required" }]}
          >
            <BaseInput.TextArea rows={4} onInput={(e) => handleInput(e, 'compliant')} />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={24} md={24} xl={24}>
          <BaseButtonsForm.Item

            name="observations"
            label={"Observations"}
            // rules={[{ required: true, message: "Observations is required" }]}
          >
            <BaseInput.TextArea rows={4} onInput={(e) => handleInput(e, 'observations')} />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={12} sm={8} md={24} xl={24}>
          <BaseButtonsForm.Item

            name="notes"
            label={"Notes"}
            // rules={[{ required: true, message: "Notes is required" }]}
          >
            <BaseInput.TextArea rows={4} onInput={(e) => handleInput(e, 'notes')} />
          </BaseButtonsForm.Item>
        </BaseCol>



      </BaseRow>
      <BaseRow gutter={[10, 10]}>
        <BaseCol xs={12} sm={8} md={12} xl={6}>
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow>

    </BaseButtonsForm>

  );
};
