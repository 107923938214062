import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ValidationFormDoctorEdit } from '@app/components/manage-my-doctors/ValidationFormEdit/ValidationForm';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

const EditMyDoctor: React.FC = () => {
  const { t } = useTranslation();
  const navigate=useNavigate();
  return (
    <>
      <PageTitle>Manage My Doctors</PageTitle>
      <div style={{display:'flex',justifyContent:'space-between'}}>
      <h4 style={{fontWeight:'bold'}}>Edit Doctor</h4>
      <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>

      </div>
      < ValidationFormDoctorEdit/>
    </>
  );
};

export default EditMyDoctor;
