import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormFilesEdit } from './ValidationFormFilesEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

interface EditVitalsProps {
    setEditType: (editType: string) => void;
    editType: String
}

const FilesModalEdit: React.FC<EditVitalsProps> = (props) => {
    const { setEditType, editType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    const [compliant, editCompliant] = useState('');
    const [observations, editObservations] = useState('');
    const [notes, editNotes] = useState('');
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={editType == 'files'} onCancel={() => setEditType('')} onOk={() => setEditType('')}>

            <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Files</h3>

            <ValidationFormFilesEdit
                formRef={formRef}
                compliant={compliant}
                editCompliant={editCompliant}
                observations={observations}
                editObservations={editObservations}
                notes={notes}
                editNotes={editNotes}
            />
        </BaseModal>

    );
};

export default FilesModalEdit;
