import * as React from 'react';
import { Component } from 'react';
import { PageTitle } from '../../common/PageTitle/PageTitle';
import { BaseRow } from '../../common/BaseRow/BaseRow';
import { BaseCol } from '../../common/BaseCol/BaseCol';
import { BaseCard } from '../../common/BaseCard/BaseCard';
import { ValidationFormClinicAdd } from '../ValidationFormAdd/ValidationForm';
import { useTranslation } from 'react-i18next';
import { ValidationFormClinicEdit } from '../ValidationFormEdit/ValidationForm';
import { ValidationFormClinicPatientEdit } from './ValidationFormEdit/ValidationForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';


const ViewClinicPatient: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    return (
        <>
            <PageTitle>Edit Patient</PageTitle>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 style={{ fontWeight: 'bold' }}>Edit Patient</h4>
                <BaseButton type="primary" onClick={() => navigate(-1)}>Back</BaseButton>

            </div>
            <ValidationFormClinicPatientEdit />


        </>
    );

}

export default ViewClinicPatient;