import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { getFacilityByIdAction, getInternalUserByIdAction, getMinimalClinicDetailByIdAction, getOtherInfoByIdAction, updateFacilityAction, updateInternalUserAction, updateOtherInfoAction } from '@app/store/slices/userSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import moment from 'moment';
import config from '@app/config';
import { readUser } from '@app/services/localStorage.service';
import { returnArea } from '@app/utils/utils';
import { getOtherInfoById } from '@app/apis/user-api';
import EditAvailabilityModal from '../EditAvailabilityModal';
import { Row, Col } from 'antd';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};



export const ValidationFormAvailabilityClinicEditEdit: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>(null);
  const [formDataClinic, setFormDataClinic] = useState<any>(null);
  const [theEntriesParent, setTheEntriesParent] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const [value, setValue] = useState('Male');
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const clinicDetails = readUser();
  if (clinicDetails!.role_id == config.roleIds.Doctor) {

    clinicDetails!.doctor_id = clinicDetails?.id;
    clinicDetails!.id = clinicDetails!.clinic_id;

  }

  const { id } = useParams();



  useEffect(() => {

    dispatch(getMinimalClinicDetailByIdAction(Number(id)))
      .unwrap()
      .then((resp) => {

        console.log({ resp })
        if (resp && !resp.error) {
          if (resp.availability) {
            let temp = JSON.parse(resp.detailedAvailability);
            setTheEntriesParent(JSON.parse(resp.availability));

            setFormData(temp);
          }
        }


        if (clinicDetails!.role_id == config.roleIds.Doctor) {


          dispatch(getMinimalClinicDetailByIdAction(Number(clinicDetails!.clinic_id)))
            .unwrap()
            .then((respClinic) => {

              console.log({ respClinic })
              if (respClinic && !respClinic.error) {
                if (respClinic.availability) {
                  let tempClinic = JSON.parse(respClinic.detailedAvailability);

                  setFormDataClinic(tempClinic);
                }
              }
            }).catch((err) => {
              notificationController.error({ message: err.message });
            })

        }

        setLoading(false);


      }).catch((err) => {
        notificationController.error({ message: err.message });
      })


  }, [])


  console.log({ formData })
  return (
    <div>
      {showAvailabilityModal && <EditAvailabilityModal
        showAvailabilityModal={showAvailabilityModal}
        setShowAvailabilityModal={setShowAvailabilityModal}
        theEntriesParent={theEntriesParent}
        days={days}
        clinicDetails={clinicDetails}
        formDataClinic={formDataClinic}
      />
      }

      {!formData && <BaseRow gutter={[10, 10]}>

        {!formData && <BaseButton onClick={() => setShowAvailabilityModal(true)}>Add Availability</BaseButton>}

      </BaseRow>
      }
      <Row>
        <Col xs={24} sm={24} md={12} xl={12} lg={12}>
          {formData && <div style={{ display: 'flex', flexDirection: 'column' }}>

            {Object.keys(formData).map((key, idx, arr) =>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ minWidth: '3vw' }}>{key}</span> : <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>{formData[key].session2Start == "" ? "Not Operational" : (formData[key].session1Start)} <b style={{ display: formData[key].session2Start == "" ? 'none' : "" }}>to</b> {formData[key].session1End}</span>
                <span style={{ display: formData[key].session2Start == "" || !formData[key].session2Start ? 'none' : '', paddingLeft: '5px', paddingRight: '5px' }}>, {(formData[key].session2Start)} - {formData[key].session2End}</span>
              </div>
            )}
          </div>
          }
            {clinicDetails!.role_id == config.roleIds.Doctor && <h4 style={{marginTop:'10px'}}><span style={{ color: 'red' }}>Note:</span> Please make sure you enter your availability according to the clinic timings.</h4>}
        </Col>
        {clinicDetails!.role_id == config.roleIds.Doctor && <Col xs={24} sm={24} md={12} xl={12} lg={12}>
       
          <strong>Clinic Timings</strong>
          {formDataClinic && <div style={{ display: 'flex', flexDirection: 'column' }}>

            {Object.keys(formDataClinic).map((key, idx, arr) =>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ minWidth: '3vw' }}>{key}</span> : <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>{formDataClinic[key].session2Start == "" ? "Not Operational" : (formDataClinic[key].session1Start)} <b style={{ display: formDataClinic[key].session2Start == "" ? 'none' : "" }}>to</b> {formDataClinic[key].session1End}</span>
                <span style={{ display: formDataClinic[key].session2Start == "" || !formDataClinic[key].session2Start ? 'none' : '', paddingLeft: '5px', paddingRight: '5px' }}>, {(formDataClinic[key].session2Start)} - {formDataClinic[key].session2End}</span>
              </div>
            )}
          </div>
          }
           
        </Col>
        }
      </Row>
      {formData && <BaseRow gutter={[10, 10]} style={{ marginTop: '20px' }}>

        {formData && <BaseButton onClick={() => setShowAvailabilityModal(true)}>Edit Availability</BaseButton>}

      </BaseRow>
      }
    </div>
  );
};
