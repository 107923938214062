import * as React from 'react';
import { Component } from 'react';
import { PageTitle } from '../common/PageTitle/PageTitle';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseCard } from '../common/BaseCard/BaseCard';
import { ValidationFormUserAdd } from './ValidationFormAdd/ValidationForm';
import { useTranslation } from 'react-i18next';


const AddEmployee: React.FC = () => {
    const { t } = useTranslation();



    return (
        <>
            <PageTitle>Add Employee</PageTitle>

            <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} sm={24} xl={24} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <BaseCard id="validation form" title={"Add new Employee"} padding="1.25rem">
                        <ValidationFormUserAdd />
                    </BaseCard>
                </BaseCol>
            </BaseRow>
        </>
    );

}

export default AddEmployee;