import { createAppointment, getAppointmentById, getAppointmentByMobile, getClinicAppointments, getDoctorAppointments, martAppointment, updateAppointment } from '@app/apis/appointments-api';
import { persistUser } from '@app/services/localStorage.service';
import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface AppointmentsModel {
    appointments: any[];
    user: any
}

const initialState: AppointmentsModel = {
    appointments: [],
    user: {}
};




export const getAppointmentByMobileAction = createAsyncThunk('appointments/getUserByMobile', async (params:any, { dispatch }) =>


    getAppointmentByMobile(params).then(async (res: any) => {

        console.log('inside action of mobile',{params})
        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);


export const getClinicAppointmentsAction = createAsyncThunk('appointments/getClinicAppointments', async (id: number, { dispatch }) =>

    getClinicAppointments(id).then(async (res: any) => {

        console.log({ res })
        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const getDoctorAppointmentsAction = createAsyncThunk('appointments/getDoctorAppointments', async (id: number, { dispatch }) =>

    getDoctorAppointments(id).then(async (res: any) => {

        console.log({ res })
        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const martAppointmentAction = createAsyncThunk('user/createAppointment', async ({ id, payload }: { id: number, payload: any }, { dispatch }) =>
    martAppointment(id, payload).then(async (res: any) => {

        console.log({ res })

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const createAppointmentAction = createAsyncThunk('user/createAppointment', async (payload: any, { dispatch }) =>
    createAppointment(payload).then(async (res: any) => {

        console.log({ res })

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const updateAppointmentAction = createAsyncThunk('user/updateAppointmentAction', async ({ id, payload }: { id: number, payload: any }, { dispatch }) =>
    updateAppointment(id, payload).then(async (res: any) => {

        console.log({ res })

        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);



export const getAppointmentByIdAction = createAsyncThunk('appointments/getAppointmentById', async (id: number, { dispatch }) =>

    getAppointmentById(id).then(async (res: any) => {

        console.log({ res })
        return res;

    }).catch((err) => {
        console.log({ err });
        if (err?.response?.data?.message) {
            throw err?.response?.data?.message
        }
        else throw err;

    })
);

export const appointmentsReducer = createSlice({
    name: 'appointments',
    initialState,
    reducers: {}
});

export default appointmentsReducer.reducer;
