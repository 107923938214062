import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState, MutableRefObject } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getAllFacilityAction, getAllSpecialitiesAction, getInternalUserByIdAction, updateExternalUserOtherInfoAction, updateInternalUserAction } from '@app/store/slices/userSlice';
import config from '@app/config';
import { returnArea } from '@app/utils/utils';
import { Form, FormInstance, Upload } from 'antd';
import { readUser } from '@app/services/localStorage.service';
import { Doctor } from '@app/api/doctors.api';

const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};



export const OtherInfoFOrm: React.FC = () => {
    const formRef = useRef<any>();
    const [isFieldsChanged, setFieldsChanged] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [formData, setFormData] = useState<any>({});

    const [specialities, setSpecialities] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [fileIdDocs, setFileIdDocs] = useState(null);

    const [fileId, setFileId] = useState(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const statesTree = useAppSelector((state) => state.user.statesTree);
    const [districts, setDistricts] = useState([]);
    const [gaunpalikas, setGaunpalikas] = useState([]);
    const [state, setState] = useState(null);
    const [district, setDistrict] = useState(null);
    const [gaunPalika, setGaunPalika] = useState(null);
    const [formReset, setFormReset] = useState(false);
    const [fileIdVideos, setFileIdVideos] = useState(null);

    const normFile = (e: any) => {
        console.log({ e })
        if (e.file.status == 'done') {
            console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
            setFileId(e.file?.response?.file?.file_id);
        }
        if (Array.isArray(e.fileList)) {
            return e.fileList;
        }

        return e && e.fileList;
    };

    const user = readUser();
    const { id } = user;



    useEffect(() => {
        let _formData = formData;
        _formData!.state = state;
        setFormReset(true);
        setDistrict(null);
        _formData!.district = null;
        setGaunPalika(null);
        _formData!.city = null;
        formRef?.current?.setFieldsValue({ city: undefined });
        formRef?.current?.setFieldsValue({ district: undefined });
        setDistricts([]);
        setGaunpalikas([]);
        setFormData(_formData);
        setDistricts(returnArea(_formData.state, null, statesTree));


        setTimeout(() => {


            setFormReset(false);

        }, 50);
    }, [state])


    useEffect(() => {
        let _formData = formData;

        setFormReset(!formReset);
        _formData.district = district;
        setGaunPalika(null);
        _formData!.city = null;
        setGaunpalikas([]);
        formRef?.current?.setFieldsValue({ city: undefined });
        setGaunpalikas(returnArea(_formData.state, _formData.district, statesTree));

        setTimeout(() => {

            setFormReset(false);

        }, 50);


    }, [district])






    const areasMapper = (data: any) => {

        setDistricts(returnArea(data.state, null, statesTree));
        setGaunpalikas(returnArea(data.state, data.district, statesTree));

    }

    useEffect(() => {

        const initializer = async () => {

            await dispatch(getAllSpecialitiesAction(1))
                .unwrap()
                .then((resp) => {

                    setSpecialities(resp);

                }).catch((err) => {
                    notificationController.error({ message: err.message });
                })

            await dispatch(getInternalUserByIdAction(Number(id)))
                .unwrap()
                .then((resp) => {

                    console.log({ resp })

                    setFileId(resp.userInfo.avatar);
                    let arr = resp && resp.avatar && Array.isArray(resp.avatar) && resp.avatar[0] !== null && resp.avatar.flatMap((x: any) => [{ name: x.name, status: 'done', url: config.API1Url + '/' + x.url, uid: x.file_id }]) || []
                    resp.userInfo.avatar = arr;
                    resp.userInfo.specialities = resp.specialities;
                    resp.userInfo.facilities = resp.facilities;

                    let docsArr = resp.userInfo.docs && resp.userInfo.docs[0] !== null && resp.userInfo.docs.flatMap((x: any) => [{ name: x.name, status: 'done', url: config.API1Url + '/' + x.url, uid: x.file_id }]) || []
                    let videosArr = resp.userInfo.videos && resp.userInfo.videos[0] !== null && resp.userInfo.videos.flatMap((x: any) => [{ name: x.name, status: 'done', url: config.API1Url + '/' + x.url, uid: x.file_id }]) || []
                    resp.userInfo.docs = docsArr;
                    resp.userInfo.videos = videosArr;
                    console.log({ resp })
                    setFormData(resp.userInfo);
                    areasMapper(resp.userInfo);

                }).catch((err) => {
                    notificationController.error({ message: err.message });
                })

            await dispatch(getAllFacilityAction())
                .unwrap()
                .then((resp) => {

                    setFacilities(resp);
                    setLoading(false);
                }).catch((err) => {
                    notificationController.error({ message: err.message });
                })


        }

        initializer();

    }, [])
    const normFileVideos = (e: any) => {
        console.log({ e })
        if (e.file.status == 'done') {
            console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
            setFileIdVideos(e.file?.response?.file?.file_id);
        }
        if (Array.isArray(e.fileList)) {
            return e.fileList;
        }

        return e && e.fileList;
    };


    const beforeUploadVideo = (file: any, fileList: any[]) => {

        const isVideo =
            file.type === "video/mp4";
        if (!isVideo) {

            notificationController.warning({ message: "only .mp4, file types are allowed" });
            return Upload.LIST_IGNORE;
        }

        if (file.size > 5e+8) {
            notificationController.warning({ message: `Max Size Supported is 500 MB` });
            return Upload.LIST_IGNORE;
        }
        return true;

    }

    const onFinish = async (values: any) => {
        setLoading(true);
        setFieldsChanged(false);
        console.log(values);
        // values.password = config.defaultPass;
        // values.confirmPassword = config.defaultPass;
        let args: any = {};
        args.user_type = 2;

        let otherDocsArr = values.docs?.flatMap((file: any) => file?.response ? file?.response?.file?.file_id : file?.uid);
        let videosArr = values.videos?.flatMap((file: any) => file?.response ? file?.response?.file?.file_id : file?.uid);
        args.docs = otherDocsArr;
        args.videos = videosArr;
        console.log(args);
        args.id = Number(id);
        args.experience = values.experience;
        args.education = values.education;
        args.description = values.description;
        args.specialities = values.specialities;
        args.facilities = values.facilities;

        dispatch(updateExternalUserOtherInfoAction(args))
            .unwrap()
            .then((resp: any) => {
                setLoading(false);
                if (resp.success) {
                    notificationController.success({ message: t('common.success') });
                    setTimeout(() => {
                        navigate(0);
                    }, 900);

                }
                else {
                    notificationController.error({ message: 'Failed to save clinic' });
                }

            }).catch((err: any) => {
                setLoading(false);
                notificationController.error({ message: err.message });
            })

    };
    const normFileDocs = (e: any) => {
        console.log({ e })
        if (e.file.status == 'done') {
            console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
            setFileIdDocs(e.file?.response?.file?.file_id);
        }
        if (Array.isArray(e.fileList)) {
            return e.fileList;
        }

        return e && e.fileList;
    };

    const beforeUpload = (file: any, fileList: any[]) => {

        const isJPG =
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png";
        if (!isJPG) {

            notificationController.warning({ message: "only .jpg, .jpeg, .png file types are allowed" });
            return Upload.LIST_IGNORE;
        }

        if (file.size > 5e+6) {
            notificationController.warning({ message: `Max Size Supported is 5 MB` });
            return Upload.LIST_IGNORE;
        }
        return true;

    }

    const isDoctor = user.role_id === config.roleIds.Doctor;
    return (
        <div>
            {!isLoading &&
                <Form
                    ref={formRef}
                    {...formItemLayout}
                    // isFieldsChanged={isFieldsChanged}
                    // onFieldsChange={() => setFieldsChanged(true)}
                    name="validateForm"
                    initialValues={formData}

                    onFinish={onFinish}
                >

                    <BaseRow gutter={[10, 10]}>



                        <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item
                                name="specialities"
                                label={"Select Specialities"}
                                rules={[{ required: true, message: "Specialities are required", type: 'array' }]}
                            >
                                <BaseSelect mode="multiple" placeholder={"Please select a specility"}>
                                    {specialities && specialities.length > 0 && specialities.map((item: any) =>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </BaseSelect>
                            </BaseButtonsForm.Item>
                        </BaseCol>

                        {!isDoctor && <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item
                                name="facilities"
                                label={"Select Facilities"}
                                rules={[{ required: true, message: "Facilities are required", type: 'array' }]}
                            >
                                <BaseSelect mode="multiple" placeholder={"Please select a facility"}>
                                    {facilities && facilities.length > 0 && facilities.map((item: any) =>
                                        <Option value={item.id} disabled={!item.isActive}>{item.name}</Option>
                                    )}

                                </BaseSelect>
                            </BaseButtonsForm.Item>
                        </BaseCol>
                        }
                        {!isDoctor ? <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item
                                name="experience"
                                label={"Established (years ago)"}
                                rules={[{ required: true, message: "Established is required" }]}
                            >
                                <BaseInput type='number' />
                            </BaseButtonsForm.Item>
                        </BaseCol>

                            :
                            <BaseCol xs={12} sm={8} md={12} xl={6}>
                                <BaseButtonsForm.Item
                                    name="experience"
                                    label={"Experience (in years)"}
                                    rules={[{ required: true, message: "Experience is required" }]}
                                >
                                    <BaseInput type='number' />
                                </BaseButtonsForm.Item>
                            </BaseCol>
                        }


                        {isDoctor && <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item
                                name="education"
                                label={"Education"}
                                rules={[{ required: true, message: "Education is required" }]}
                            >
                                <BaseInput.TextArea rows={4} />
                            </BaseButtonsForm.Item>
                        </BaseCol>
                        }

                        <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item
                                name="description"
                                label={"Description"}
                                rules={[{ required: true, message: "Description is required" }]}
                            >
                                <BaseInput.TextArea rows={4} />
                            </BaseButtonsForm.Item>
                        </BaseCol>


                        {isDoctor ? <BaseCol xs={12} sm={8} md={12} xl={12} lg={10}>
                            <BaseButtonsForm.Item
                                name="docs"
                                label={"Upload verification documents"}
                                // valuePropName="fileList"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select documents (Max 10)"
                                    }
                                ]}
                                getValueFromEvent={normFileDocs}
                            >
                                <BaseUpload  name="file" action={config.API1Url + '/users/upload'} multiple={true} maxCount={10} defaultFileList={formData.docs}>
                                    <BaseButton type="default" icon={<UploadOutlined />}>
                                        {t('forms.validationFormLabels.clickToUpload')}
                                    </BaseButton>
                                </BaseUpload>
                            </BaseButtonsForm.Item>
                        </BaseCol>

                            :
                            <BaseCol xs={12} sm={8} md={12} xl={8} lg={12}>
                                <BaseButtonsForm.Item
                                    name="docs"
                                    label={"Other Photos (Max 10)"}
                                    // valuePropName="fileList"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a photo"
                                        }
                                    ]}
                                    getValueFromEvent={normFileDocs}
                                >
                                    <BaseUpload beforeUpload={beforeUpload} name="file" action={config.API1Url + '/users/upload'} multiple={true} maxCount={10} defaultFileList={formData.docs}>
                                        <BaseButton type="default" icon={<UploadOutlined />}>
                                            {t('forms.validationFormLabels.clickToUpload')}
                                        </BaseButton>
                                    </BaseUpload>
                                </BaseButtonsForm.Item>
                            </BaseCol>
                        }


                        {!isDoctor && <BaseCol xs={12} sm={8} md={12} xl={12} lg={10}>
                            <BaseButtonsForm.Item
                                name="videos"
                                label={"Videos (Max 6)"}
                                // valuePropName="fileList"
                                rules={[
                                    {
                                        required: false,
                                        message: "Please select a video"
                                    }
                                ]}
                                getValueFromEvent={normFileVideos}
                            >
                                <BaseUpload beforeUpload={beforeUploadVideo} name="file" action={config.API1Url + '/users/upload'} multiple={true} maxCount={10} defaultFileList={formData.videos}>
                                    <BaseButton type="default" icon={<UploadOutlined />}>
                                        {t('forms.validationFormLabels.clickToUpload')}
                                    </BaseButton>
                                </BaseUpload>
                            </BaseButtonsForm.Item>
                        </BaseCol>
                        }


                    </BaseRow>

                    <BaseRow gutter={[10, 10]}>
                        <BaseCol xs={12} sm={8} md={12} xl={6}>
                            <BaseButtonsForm.Item>
                                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                                    Update
                                </BaseButton>
                            </BaseButtonsForm.Item>
                        </BaseCol>
                    </BaseRow>
                </Form>
            }
        </div >
    );
};
