import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { BaseButton } from '../../../../common/BaseButton/BaseButton';
import { Modal } from 'antd';
import { ValidationFormVitalsEdit } from './ValidationFormVitalsEdit';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { ValidationFormVitalsAdd } from './ValidationFormVitalsAdd';

interface EditVitalsProps {
    setAddType: (editType: string) => void;
    addType: String,
    getPatientDataAll:any,
    setPatientDataId:any,
    patientDataId:number
}

const AddVitalsModal: React.FC<EditVitalsProps> = (props) => {
    const { setAddType, addType } = props;
    const { t } = useTranslation();
    let navigate = useNavigate();
    const formRef = useRef<any>();
    return (



        <BaseModal getContainer={false} size={'large'} {...props} open={addType == '1'} onCancel={() => setAddType('')} onOk={() => setAddType('')} footer={<></>}>

            <h3 style={{ marginBottom: '20px', borderBottom: '1px solid grey' }}>Add New Vital Signs</h3>

            <ValidationFormVitalsAdd
            setAddType={setAddType}
            getPatientDataAll={props.getPatientDataAll}
            setPatientDataId={props.setPatientDataId}
                    patientDataId={props.patientDataId}
            // formRef={formRef}
            />
        </BaseModal>

    );
};

export default AddVitalsModal;
