import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import config from '@app/config';
import { getRolesAction } from '@app/store/slices/authSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createInternalUserAction } from '@app/store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { returnArea } from '@app/utils/utils';
import { Form } from 'antd';

console.log({ config })
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};


export const ValidationFormUserAdd: React.FC = () => {
  const formRef=useRef<any>();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statesTree = useAppSelector((state) => state.user.statesTree);
  const [districts, setDistricts] = useState([]);
  const [gaunpalikas, setGaunpalikas] = useState([]);
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [gaunPalika, setGaunPalika] = useState(null);
  const [formReset, setFormReset] = useState(false);
  const dispatch = useAppDispatch();

  console.log({ statesTree })

  useEffect(() => {
    dispatch(getRolesAction(1))
      .unwrap()
      .then((resp) => {

        setRoles(resp);

      }).catch((err) => {
        notificationController.error({ message: err.message });
      })
  }, [])

  useEffect(() => {

    setFormReset(true);
    setDistrict(null);
    setGaunPalika(null);
    formRef.current.setFieldsValue({city:undefined});
    formRef.current.setFieldsValue({district:undefined});
    setDistricts([]);
    setGaunpalikas([]);

    setDistricts(returnArea(state, null, statesTree));


    setTimeout(() => {


      setFormReset(false);

    }, 50);
  }, [state])


  useEffect(() => {

    setFormReset(!formReset);
    setGaunPalika(null);
    setGaunpalikas([]);
    formRef.current.setFieldsValue({city:undefined});
    setGaunpalikas(returnArea(state, district, statesTree));

    setTimeout(() => {

      setFormReset(false);

    }, 50);


  }, [district])





  const onFinish = async (values: any) => {
    setLoading(true);


    setFieldsChanged(false);
    values.avatar = fileId;
    values.password = config.defaultPass;
    values.confirmPassword = config.defaultPass;
    values.user_type = 1;
    typeof (values.status) !== 'boolean' ? values.status = false : values.status = values.status;

    console.log(values);

    dispatch(createInternalUserAction(values))
      .unwrap()
      .then((resp: any) => {
        setLoading(false);
        if (resp.success) {
          notificationController.success({ message: t('common.success') });
          navigate(-1);
        }
        else {
          notificationController.error({ message: 'Failed to save user' });
        }

      }).catch((err: any) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      })



  };

  const normFile = (e: any) => {
    console.log({ e })
    if (e.file.status == 'done') {
      console.log('e.file?.response?.file?.file_id', e.file?.response?.file?.file_id);
      setFileId(e.file?.response?.file?.file_id);
    }
    if (Array.isArray(e.fileList)) {
      return e.fileList;
    }

    return e && e.fileList;
  };

  return (
    <>
    
        <Form
          {...formItemLayout}
          // isFieldsChanged={isFieldsChanged}
          // onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          ref={formRef}
          initialValues={{
            'input-number': 3,
            'checkbox-group': ['A', 'B'],
            rate: 3.5,
          }}
         
          onFinish={onFinish}
        >

          <BaseRow gutter={[10, 10]}>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="firstName"
                label={"First Name"}
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="lastName"
                label={"Last Name"}
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="email"
                label={"Email"}
                rules={[{ required: true, type: "email", message: "Email is required and must be a valid email" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="userName"
                label={"Username"}
                rules={[{ required: true, message: "Username is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="phone"
                label={"Phone"}
                rules={[{ required: true, message: "Phone is required" },
                  // {
                  //   validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  //     value.length < 3 ? cb("too short") : cb();
                  //   }, message: "Please enter a valid 10 digit phone number"
                  // }
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="address"
                label={"Address"}
                rules={[{ required: true, message: "Address is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="pincode"
                label={"Pincode"}
                rules={[{ required: true, message: "Pincode is required" }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="state"
                label={"Select State"}
                hasFeedback
                rules={[{ required: true, message: "State is required" }]}
                initialValue={state}
              >
                <BaseSelect placeholder={"Please select a state"} onChange={(e: any) => setState(e)}>
                  {statesTree && statesTree.map((item: any) =>
                    <Option value={item?.id}>{item?.name}</Option>

                  )}

                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="district"
                label={"Select District"}
                hasFeedback
                rules={[{ required: true, message: "District is required" }]}
                initialValue={district}

              >
                <BaseSelect placeholder={"Please select a district"} onChange={(e: any) => setDistrict(e)}>
                  {districts && districts.map((item: any) =>
                    <Option value={item?.id}>{item?.district_name}</Option>

                  )}


                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="city"
                label={"Select Gaunpalika"}
                hasFeedback
                rules={[{ required: true, message: "Gaunpalika is required" }]}
                initialValue={gaunPalika}
              >
                <BaseSelect placeholder={"Please select a gaunpalika"} onChange={(e: any) => setGaunPalika(e)}>
                  {gaunpalikas && gaunpalikas.map((item: any) =>
                    <Option value={item?.id}>{item?.gaunpalika_name}</Option>

                  )}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>


            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="role_id"
                label={"Select a Role"}
                rules={[{ required: true, message: "Role is required" }]}
              >
                <BaseSelect placeholder={"Please select a role"}>
                  {roles && roles.map((x: any) =>
                    <Option value={x?.id}>{x?.name}</Option>
                  )}


                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item name="status" label="Status" valuePropName="checked">
                <BaseSwitch />
              </BaseButtonsForm.Item>


            </BaseCol>

            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item
                name="avatar"
                label={"Upload your logo"}
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please select a logo"
                  }
                ]}
                getValueFromEvent={normFile}
              >
                <BaseUpload name="file" action={config.API1Url + '/users/upload'} listType="picture" maxCount={1}>
                  <BaseButton type="default" icon={<UploadOutlined />}>
                    {t('forms.validationFormLabels.clickToUpload')}
                  </BaseButton>
                </BaseUpload>
              </BaseButtonsForm.Item>
            </BaseCol>

          </BaseRow>

          <BaseRow gutter={[10, 10]}>
            <BaseCol xs={12} sm={8} md={12} xl={6}>
              <BaseButtonsForm.Item>
                <BaseButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('common.submit')}
                </BaseButton>
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </Form>
     
    </>
  );
};
